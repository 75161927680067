import styled from 'styled-components';
import { motion } from 'framer-motion';

import { COLORS } from '../../../styles/constants';

export const Container = styled(motion.div)`
  padding: 0 55px 64px 55px;

  width: 100%;
  margin-left: auto;

  @media (min-width: 1129px) {
    width: calc(100% - 300px);
  }

  @media (max-width: 450px) {
    padding: 0 24px;
  }

  h1 {
    color: ${COLORS.text};
    font-size: 32px;
    margin-bottom: 15px;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;

    @media (max-width: 590px) {
      font-size: 24px;
    }
  }
`;

export const CadastralData = styled.div`
  width: 100%;
  max-width: 480px;

  :first-child {
    margin-right: 18px;
  }

  @media (max-width: 600px) {
    :last-child {
      margin-top: 24px;
    }
  }

  h3 {
    font-size: 18px;
    color: ${COLORS.text};
    opacity: 0.9;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;
  }

  small {
    color: ${COLORS.text};
    opacity: 0.6;
  }

  button {
    margin: 0;
    margin-top: 8px;
  }
`;

export const CadastralForm = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 23px;

  input {
    border-radius: 50px;
    background-color: ${COLORS.background};
    border: 1px solid rgba(40, 41, 39, 0.1);
    border-radius: 50px;
    height: 42px;
    padding: 12px 22px;
    font-size: 14px;
    color: ${COLORS.text};
    margin-bottom: 14px;

    appearance: none;

    transition: all 180ms ease-in-out 0s;

    :focus {
      border-color: ${COLORS.primary};
    }

    :hover {
      ::placeholder {
        color: ${COLORS.text};
      }
    }

    ::placeholder {
      :focus {
        color: ${COLORS.text};
      }
    }

    ::placeholder {
      color: rgba(40, 41, 39, 0.5);

      transition: all 180ms ease-in-out 0s;
    }
  }
`;

export const Input = styled.input`
  border-radius: 50px;
  background-color: ${COLORS.background};
  border: 1px solid rgba(40, 41, 39, 0.1);
  border-radius: 50px;
  height: 42px;
  padding: 12px 22px;
  font-size: 14px;
  color: ${COLORS.text};
  margin-bottom: 14px;

  appearance: none;

  transition: all 180ms ease-in-out 0s;

  :focus {
    border-color: ${COLORS.primary};
  }

  :hover {
    ::placeholder {
      color: ${COLORS.text};
    }
  }

  ::placeholder {
    :focus {
      color: ${COLORS.text};
    }
  }

  ::placeholder {
    color: rgba(40, 41, 39, 0.5);

    transition: all 180ms ease-in-out 0s;
  }
`;

export const BankData = styled.div`
  max-width: 480px;
  width: 100%;
  margin-top: 12px;

  h3 {
    margin-bottom: 23px;
    font-size: 18px;
    color: ${COLORS.text};
    opacity: 0.9;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;
  }
`;

export const Button = styled.button`
  background-color: ${COLORS.primary};
  height: 42px;
  border-radius: 500px;
  color: ${COLORS.white};
  max-width: 200px;
  width: 100%;
  font-size: 15px;
  margin-left: auto;

  @media (max-width: 590px) {
    margin-bottom: 40px;
  }

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  transition: all 180ms ease-in-out 0s;

  svg {
    margin-left: 8px;
  }

  :disabled {
    opacity: 0.6;

    :hover {
      box-shadow: none;
    }
  }

  :hover {
    box-shadow: 0px 6px 45px rgba(0, 179, 92, 0.31);
  }
`;

export const BankForm = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border-radius: 50px;
    background-color: ${COLORS.background};
    border: 1px solid rgba(40, 41, 39, 0.1);
    border-radius: 50px;
    height: 42px;
    padding: 12px 22px;
    font-size: 14px;
    color: ${COLORS.text};
    margin-bottom: 14px;
    width: 100%;

    appearance: none;

    transition: all 180ms ease-in-out 0s;

    :focus {
      border-color: ${COLORS.primary};
    }

    :hover {
      ::placeholder {
        color: ${COLORS.text};
      }
    }

    ::placeholder {
      :focus {
        color: ${COLORS.text};
      }
    }

    ::placeholder {
      color: rgba(40, 41, 39, 0.5);

      transition: all 180ms ease-in-out 0s;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 25px;
    margin-left: 12px;
    margin-bottom: 15px;
    color: ${COLORS.primary};

    @media (min-width: 1128px) {
      display: none;
    }
  }
`;

export const CadastralContainer = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
