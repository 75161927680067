import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../styles/constants';

interface TableFieldProps {
  isAdmin?: boolean;
}

export const Container = styled(motion.div)`
  width: 100%;
  margin: 40px 0 45px 0;

  @media (max-width: 450px) {
    margin: 24px 0 30px 0;
  }
`;

export const TableHeader = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 80px 0 45px;

  @media (max-width: 450px) {
    padding: 0 30px 0 0;
  }
`;

export const TableBody = styled.div`
  margin-top: 24px;
`;

export const ColumnName = styled.li<TableFieldProps>`
  color: ${COLORS.text};
  font-size: 15px;
  opacity: 0.5;

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  ${props =>
    props.isAdmin &&
    css`
      :last-child {
        width: 160px;
      }
    `}

  @media (max-width: 940px) {
    :not(:first-child) {
      display: none;
    }
  }
`;

export const TableItem = styled.div`
  background-color: ${COLORS.lightGrey};
  height: 50px;
  padding: 0 60px 0 45px;
  font-size: 14.5px;
  border-radius: 4px;
  color: ${COLORS.text};
  position: relative;
  margin-top: 12px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 0 30px;
  }

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  ::before {
    position: absolute;
    left: 0;
    content: '';
    width: 10px;
    background: rgba(255, 31, 98, 0.2);
    height: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const TableField = styled.div<TableFieldProps>`
  display: flex;
  align-items: center;

  :nth-child(1) {
    width: 100%;
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  :nth-child(2) {
    margin-left: -58px;
  }

  :nth-child(3) {
    margin-left: -50px;
  }

  :nth-child(4) {
    margin-left: 5px;
  }

  :nth-child(5) {
    margin-right: 10px;
  }

  :nth-child(5) {
    margin-left: 8px;
  }

  ${props =>
    props.isAdmin &&
    css`
      :nth-child(5) {
        margin-right: -38px;
      }

      :nth-child(6) {
        margin-left: 5px;
        width: 180px;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}

  @media (max-width: 940px) {
    :not(:first-child) {
      display: none;
    }
  }

  svg {
    font-size: 17px;
    margin-left: 4.5px;
  }
`;

export const EmptyTable = styled.div`
  color: ${COLORS.text};
  border-radius: 6px;
  min-height: 100px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;
