import { format, parseISO } from 'date-fns';
import api from './api';

import { calculateHours } from '../utils';

function convertTime(time) {
  const newTime = time.value.substring(0, 5);

  return `${newTime}:00.000`;
}

function convertToDate(date) {
  const correctDate = date.value.split('/').reverse().join('-');

  return format(parseISO(correctDate), 'yyyy-MM-dd');
}

export default {
  async get(selectedAppointment) {
    const response = await api.get(`time-entries/${selectedAppointment}`);

    return response.data;
  },

  async create(appointment, user_id) {
    const { project, description, date, time_start, time_finish } = appointment;

    const totalHoursSum = calculateHours(time_start.value, time_finish.value);

    if (totalHoursSum < 6) {
      return api.post('time-entries', {
        user_id,
        project_id: project.value,
        description: description.value || '',
        date: convertToDate(date),
        time_start: convertTime(time_start),
        time_finish: convertTime(time_finish),
      });
    }

    throw new Error(
      'Por favor, cadastre os apontamentos com um intervalo diário!',
    );
  },

  async update(appointment, user_id, selectedAppointment) {
    const { project, description, date, time_start, time_finish } = appointment;

    await api.put(`time-entries/${selectedAppointment}`, {
      user_id,
      project_id: project.value,
      description: description.value || '',
      date: convertToDate(date),
      time_start: convertTime(time_start),
      time_finish: convertTime(time_finish),
    });
  },
};
