/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';

import { VscChromeClose } from 'react-icons/vsc';
import { BiPlus } from 'react-icons/bi';

import { Multiselect } from 'multiselect-react-dropdown';

import talentsService from '../../services/talents';

import {
  Container,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  Select,
  FormRow,
  TextArea,
  Checkbox,
} from './styles';

import api from '../../services/api';

const AppointmentsModal = ({
  isOpened,
  toggleChildOpen,
  modalType = 'New',
  selectedItem,
  handleChange,
}) => {
  const [talent, setTalent] = useState({} as any);
  const [selectedOS, setSelectedOS] = useState('');
  const [selectedOwnComputer, setSelectedOwnComputer] = useState('');
  const [selectedEnglishLevel, setSelectedEnglishLevel] = useState('');
  const [
    selectedAcademicQualification,
    setSelectedAcademicQualification,
  ] = useState('');
  const [selectedOrigin, setSelectedOrigin] = useState('');
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isAvailable, setIsAvailable] = useState(true);

  const formRef = useRef(null);

  function toggleOpen() {
    toggleChildOpen();
  }

  useEffect(() => {
    formRef.current.reset();
    getSkills();
    setTalent({});
    setSelectedOS('');
    setSelectedOrigin('');
    setSelectedOwnComputer('');
    setSelectedAcademicQualification('');
    setSelectedEnglishLevel('');
    setSelectedSkills([]);

    if (modalType === 'Edit' || modalType === 'View') {
      getTalent();
    }
  }, [toggleChildOpen]);

  async function getTalent() {
    const response = await talentsService.getById(selectedItem);

    setSelectedOS(response.os);
    setSelectedOwnComputer(response.own_computer);
    setSelectedEnglishLevel(response.english_level);
    setSelectedAcademicQualification(response.academic_qualification);
    setSelectedOrigin(response.origin);
    setSelectedSkills(response.skills);
    setIsAvailable(response.available !== null ? response.available : true);

    setTalent(response);
  }

  function handleFormEvent(event) {
    event.preventDefault();

    if (modalType === 'New') {
      createTalent(event);
    }

    if (modalType === 'Edit') {
      editTalent(event);
    }
  }

  async function getSkills() {
    const skillsList = await talentsService.getSkills();

    setSkills(skillsList);
  }

  async function createTalent(event) {
    const {
      available_reason,
      description,
      name,
      age,
      state,
      city,
      referral,
      phone,
      wage_expectation,
      skills_experience,
    } = event.target.elements;

    const selectedSkillIds = [];

    if (selectedSkills.length) {
      selectedSkills.map(item => {
        return selectedSkillIds.push(item.id);
      });
    }

    await api.post('talents', {
      description: description.value,
      name: name.value,
      age: age.value,
      state: state.value,
      city: city.value,
      phone: phone.value,
      referral: referral ? referral.value : '',
      wage_expectation: wage_expectation.value,
      own_computer: selectedOwnComputer,
      skills_experience: skills_experience ? skills_experience.value : '',
      english_level: selectedEnglishLevel,
      academic_qualification: selectedAcademicQualification,
      origin: selectedOrigin,
      os: selectedOS,
      skills: selectedSkillIds,
      available_reason: available_reason ? available_reason.value : '',
      available: isAvailable,
    });

    handleChange();
    toggleOpen();
  }

  async function editTalent(event) {
    const {
      description,
      available_reason,
      name,
      age,
      state,
      city,
      referral,
      phone,
      wage_expectation,
      skills_experience,
    } = event.target.elements;

    const selectedSkillIds = [];

    if (selectedSkills.length) {
      selectedSkills.map(item => {
        return selectedSkillIds.push(item.id);
      });
    }

    await api.put(`talents/${selectedItem}`, {
      description: description.value,
      name: name.value,
      age: age.value,
      state: state.value,
      city: city.value,
      phone: phone.value,
      referral: referral ? referral.value : '',
      wage_expectation: wage_expectation.value,
      own_computer: selectedOwnComputer,
      english_level: selectedEnglishLevel,
      academic_qualification: selectedAcademicQualification,
      origin: selectedOrigin,
      os: selectedOS,
      skills: selectedSkillIds,
      skills_experience: skills_experience ? skills_experience.value : '',
      available_reason: available_reason ? available_reason.value : '',
      available: isAvailable,
    });

    handleChange();
    toggleOpen();
  }

  function onSelectSkill(selectedList) {
    setSelectedSkills(selectedList);
  }

  function onRemoveSkill(selectedList) {
    setSelectedSkills(selectedList);
  }

  return (
    <Container isOpened={isOpened}>
      <ModalHeader>
        {modalType === 'New' && <h1>Novo Talento</h1>}
        {modalType === 'Edit' && <h1>Editar Talento</h1>}
        {modalType === 'View' && <h1>Talento</h1>}

        <VscChromeClose onClick={toggleOpen} />
      </ModalHeader>

      <ModalBody>
        <form onSubmit={handleFormEvent} ref={formRef}>
          <FormRow>
            <label>Nome do Talento</label>
            <Input
              type="text"
              name="name"
              placeholder="Nome do Talento"
              defaultValue={talent.name || ''}
              disabled={modalType === 'View'}
              required
            />

            <label>Por onde veio</label>
            <Select
              name="origin"
              placeholder="Por onde veio"
              value={selectedOrigin}
              required
              disabled={modalType === 'View'}
              onChange={value => setSelectedOrigin(value.target.value)}
            >
              <option value="" disabled>
                Por onde veio
              </option>
              <option value="Linkedin">Linkedin</option>
              <option value="Infojobs">Infojobs</option>
              <option value="Email">Email</option>
              <option value="Indicação">Indicação</option>
            </Select>

            {selectedOrigin === 'Indicação' && (
              <Input
                type="text"
                name="referral"
                placeholder="Quem indicou"
                defaultValue={talent.referral || ''}
                disabled={modalType === 'View'}
                required
              />
            )}

            <label>Idade</label>
            <Input
              type="number"
              name="age"
              placeholder="Idade"
              defaultValue={talent.age || ''}
              disabled={modalType === 'View'}
              required
            />

            <label>Telefone</label>
            <Input
              type="text"
              name="phone"
              placeholder="Telefone"
              defaultValue={talent.phone || ''}
              disabled={modalType === 'View'}
              required
            />

            <label>Computador Próprio</label>
            <Select
              name="own_computer"
              placeholder="Computador Próprio"
              value={selectedOwnComputer}
              required
              disabled={modalType === 'View'}
              onChange={value => setSelectedOwnComputer(value.target.value)}
            >
              <option value="" disabled>
                Computador Próprio
              </option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </Select>
          </FormRow>

          <FormRow>
            <label>Formação</label>
            <Select
              name="academic_qualification"
              placeholder="Formação"
              value={selectedAcademicQualification}
              required
              disabled={modalType === 'View'}
              onChange={value =>
                setSelectedAcademicQualification(value.target.value)
              }
            >
              <option value="" disabled>
                Formação
              </option>
              <option value="Médio incompleto">Médio Incompleto</option>
              <option value="Médio completo">Médio Completo</option>
              <option value="Superior incompleto">Superior Incompleto</option>
              <option value="Superior completo">Superior Completo</option>
            </Select>

            <label>Nível de Inglês</label>
            <Select
              name="english_level"
              placeholder="Nível de Inglês"
              value={selectedEnglishLevel}
              required
              disabled={modalType === 'View'}
              onChange={value => setSelectedEnglishLevel(value.target.value)}
            >
              <option value="" disabled>
                Nível de Inglês
              </option>
              <option value="Básico">Básico</option>
              <option value="Intermediário">Intermediário</option>
              <option value="Avançado">Avançado</option>
              <option value="Avançado">Fluente</option>
            </Select>

            <label>Pretensão Salarial</label>
            <Input
              type="text"
              name="wage_expectation"
              placeholder="Pretensão Salarial"
              defaultValue={talent.wage_expectation || ''}
              disabled={modalType === 'View'}
              required
            />

            <label>Estado</label>
            <Input
              type="text"
              name="state"
              placeholder="Estado"
              defaultValue={talent.state || ''}
              disabled={modalType === 'View'}
              required
            />

            <label>Cidade</label>
            <Input
              type="text"
              name="city"
              placeholder="Cidade"
              defaultValue={talent.city || ''}
              disabled={modalType === 'View'}
              required
            />
          </FormRow>

          <FormRow>
            <label>Conhecimentos Técnicos</label>
            <Multiselect
              options={skills}
              displayValue="name"
              name="skills"
              id="skills_required"
              placeholder="Conhecimentos Técnicos"
              emptyRecordMsg="Nenhuma opção encontrada"
              selectedValues={selectedSkills}
              onSelect={onSelectSkill}
              onRemove={onRemoveSkill}
              groupBy="type"
              disable={modalType === 'View'}
              required
            />

            <label>Descrição</label>
            <TextArea
              name="skills_experience"
              placeholder="Descrição"
              defaultValue={talent.skills_experience || ''}
              disabled={modalType === 'View'}
              required
            />

            <label>Observações</label>
            <TextArea
              name="description"
              placeholder="Observações"
              defaultValue={talent.description || ''}
              disabled={modalType === 'View'}
              required
            />
          </FormRow>

          <FormRow>
            <label>Sistema Operacional</label>
            <Select
              name="os"
              placeholder="Sistema Operacional"
              value={selectedOS}
              required
              disabled={modalType === 'View'}
              onChange={value => setSelectedOS(value.target.value)}
            >
              <option value="" disabled>
                Sistema Operacional
              </option>
              <option value="macOS">macOS</option>
              <option value="Linux">Linux</option>
              <option value="Windows">Windows</option>
            </Select>

            <Checkbox>
              <input
                type="checkbox"
                name=""
                id=""
                checked={isAvailable}
                onChange={() => setIsAvailable(!isAvailable)}
              />
              <label>Disponível</label>
            </Checkbox>

            {!isAvailable && (
              <>
                <label>Motivo Indisponibilidade</label>
                <TextArea
                  name="available_reason"
                  placeholder="Motivo Indisponibilidade"
                  defaultValue={talent.available_reason || ''}
                  disabled={modalType === 'View'}
                  required
                />
              </>
            )}

            {modalType === 'New' && (
              <Button type="submit">
                Cadastrar Talento
                <BiPlus />
              </Button>
            )}
            {modalType === 'Edit' && <Button>Editar Talento</Button>}
          </FormRow>
        </form>
      </ModalBody>
    </Container>
  );
};

export default AppointmentsModal;
