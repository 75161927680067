/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useHistory, NavLink as Link } from 'react-router-dom';

import { VscChromeClose } from 'react-icons/vsc';

import NewLogo from '../../assets/New-Logo.svg';

import User from '../../assets/User.svg';
import Appointments from '../../assets/Appointments.svg';
import Reports from '../../assets/Reports.svg';
import Talents from '../../assets/Talents.svg';
import Opportunities from '../../assets/Opportunities.svg';

import { CONTAINER_ANIMATION, LOGO_ANIMATION } from './animations';

import { useAuth } from '../../hooks/AuthContext';
import { formatDate, isAdmin, isAdminOrRecrutier } from '../../utils';

import {
  Container,
  Header,
  Content,
  ListItems,
  LogoutButton,
  HeaderLogo,
  UsernameContainer,
  Name,
} from './styles';

const Sidebar = ({ isOpened, closeSidebar }) => {
  const history = useHistory();
  const { signOut, user } = useAuth() as any;
  const todayFormattedDate = formatDate(new Date());
  const userIsAdmin = user.roles ? isAdmin(user) : false;
  const userIsAdminOrRecruiter =
    user.roles || user.role ? isAdminOrRecrutier(user) : false;

  function Logout() {
    signOut();

    if (userIsAdmin) {
      history.push('/admin');
    } else {
      history.push('/');
    }
  }

  function handleCloseSidebar() {
    closeSidebar();
  }

  return (
    <Container variants={CONTAINER_ANIMATION} isOpened={isOpened}>
      <Header>
        <HeaderLogo variants={LOGO_ANIMATION}>
          <img src={NewLogo} alt="Bravosul" />

          <VscChromeClose onClick={handleCloseSidebar} />
        </HeaderLogo>

        <UsernameContainer>
          Olá,
          <Name>{user?.username || user?.firstname}</Name>!
        </UsernameContainer>

        <small>{todayFormattedDate}</small>
      </Header>

      <Content>
        <ListItems>
          {!userIsAdmin && (
            <Link exact to="/dashboard" activeClassName="active">
              <img src={Appointments} alt="Appointments" />
              Apontamentos
            </Link>
          )}

          {userIsAdminOrRecruiter && (
            <Link exact to="/dashboard/talentos" activeClassName="active">
              <img src={Talents} alt="Talentos" />
              Talentos
            </Link>
          )}

          {userIsAdminOrRecruiter && (
            <Link exact to="/dashboard/oportunidades" activeClassName="active">
              <img src={Opportunities} alt="Oportunidades" />
              Oportunidades
            </Link>
          )}

          <Link exact to="/dashboard/relatorios" activeClassName="active">
            <img src={Reports} alt="Reports" />
            Relatórios
          </Link>

          {!userIsAdmin && (
            <Link exact to="/dashboard/meus-dados" activeClassName="active">
              <img src={User} alt="Meus Dados" />
              Meus dados
            </Link>
          )}
        </ListItems>

        <LogoutButton onClick={Logout}>Sair</LogoutButton>
      </Content>
    </Container>
  );
};

export default Sidebar;
