import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Montserrat',
  src: '/fonts/Montserrat/Montserrat-Regular.ttf',
  format: 'truetype',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    padding: '30px, 40px',
    flexGrow: 1,
  },
  home: {
    minHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '120px',
  },
  title: {
    fontSize: 26,
    marginTop: 20,
    color: '#282927',
    fontFamily: 'Montserrat',
  },
  subtitle: {
    opacity: 0.8,
    fontSize: 17,
    marginTop: 6,
    marginBottom: 18,
    color: '#282927',
    fontFamily: 'Montserrat',
  },
  project: {
    marginBottom: 24,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 18,
  },
  name: {
    color: '#282927',
    fontFamily: 'Montserrat',
  },
  table: {
    width: '100%',
    marginTop: 14,
    border: '1pt solid #e9e9e9',
    borderRight: 0,
    borderBottom: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '100%',
    width: '100%',
  },
  headerItem: {
    color: '#282927',
    fontSize: 13,
    textAlign: 'center',
    fontWeight: 'bold',
    flex: 1,
    borderRight: '1pt solid #e9e9e9',
    borderBottom: '1pt solid #e9e9e9',
    padding: 2,
    fontFamily: 'Montserrat',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '100%',
    width: '100%',
  },
  isWeekendBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '100%',
    width: '100%',
    backgroundColor: '#a8a3a2',
  },
  bodyItem: {
    color: '#282927',
    fontSize: 13,
    textAlign: 'center',
    flex: 1,
    padding: 3,
    borderRight: '1pt solid #e9e9e9',
    borderBottom: '1pt solid #e9e9e9',
    fontFamily: 'Montserrat',
  },
  total: {
    borderBottom: '1pt solid #e9e9e9',
    borderRight: '1pt solid #e9e9e9',
    height: 30,
    paddingLeft: 19,
    paddingRight: 19,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  totalText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  container: {
    width: '100%',
  },
});

export default styles;
