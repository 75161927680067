import styled from 'styled-components';
import { motion } from 'framer-motion';

import { COLORS } from '../../styles/constants';

export const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
`;

export const Content = styled(motion.div)`
  background-color: ${COLORS.white};
  width: 100%;
  height: 100%;
  max-height: 400px;
  max-width: 425px;
  margin: 12% auto;
  padding: 50px;
  z-index: 99999;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 505px) {
    max-width: 305px;
    max-height: 312px;
    padding: 30px;
    margin: 45% auto;
  }

  img {
    width: 56px;
    height: 56px;
  }

  svg {
    font-size: 38px;
    color: #5cb85c;
  }

  h1 {
    margin: 26px 0 0 0;
    line-height: 40.48px;
    font-size: 32px;
    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;

    @media (max-width: 505px) {
      font-size: 22px;
      line-height: 27.83px;
    }
  }
`;

export const ExcludeUser = styled.button`
  width: 100%;
  max-width: 272px;
  height: 50px;
  margin: 27px auto 14px;
  border-radius: 500px;
  font-size: 16px;
  color: ${COLORS.white};
  background-color: #6cc070;
  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;
  transition: all 300ms ease-in-out 0s;
  -webkit-transition: all 300ms ease-in-out 0s;
  -moz-transition: all 300ms ease-in-out 0s;
  -ms-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  @media (max-width: 505px) {
    max-width: 224px;
    font-size: 14px;
  }
  &:hover {
    box-shadow: 0px 6px 45px rgba(189, 0, 0, 0.31);
  }
`;

export const CloseModal = styled.span`
  font-size: 14px;
  color: ${COLORS.text};
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: transparent;
  font-family: 'Circular-Std-Book', Arial, Helvetica, sans-serif;
  transition: all 300ms ease-in-out 0s;
  -webkit-transition: all 300ms ease-in-out 0s;
  -moz-transition: all 300ms ease-in-out 0s;
  -ms-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  margin-top: 24px;

  &:hover {
    text-decoration-color: ${COLORS.text};
  }
  @media (max-width: 505px) {
    font-size: 14px;
  }
`;

export const Round = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: rgba(92, 184, 92, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
`;
