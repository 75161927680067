import React, { useState, useCallback } from 'react';

import { BiLock } from 'react-icons/bi';
import { FiCheck } from 'react-icons/fi';
import { CgMenuRight } from 'react-icons/cg';

import UpdateUserModal from '../../../components/UpdateUserModal';
import SuccessModal from '../../../components/SuccessModal';

import { useAuth } from '../../../hooks/AuthContext';
import { cpfMask, cpfDefault, cnpjMask, cnpjDefault } from '../../../utils';

import api from '../../../services/api';

import { USER_DATA_ANIMATION } from './animations';

import {
  Container,
  CadastralData,
  CadastralForm,
  Input,
  BankData,
  Button,
  BankForm,
  ContainerHeader,
  CadastralContainer,
} from './styles';

const UserData = ({ setIsOpened }) => {
  const { user, forgotPassword } = useAuth() as any;
  const [cpfInput, setCPFInput] = useState(user.cpf ? cpfMask(user.cpf) : '');
  const [cnpjInput, setCNPJInput] = useState(
    user.cnpj ? cnpjMask(user.cnpj) : '',
  );

  const [isUpdated, setIsUpdated] = useState(false);
  const [isSuccessModalOpened, setIsSucessModalOpened] = useState(false);

  const UserForm = {};

  function handleCPFInputchange(event) {
    setCPFInput(cpfMask(event.target.value));
  }

  function handleCNPJInputChange(event) {
    setCNPJInput(cnpjMask(event.target.value));
  }

  function handleFormValues(form) {
    const pickedForm = (({
      username,
      cpf,
      bank,
      agency,
      account,
      personal_email,
      nationality,
      marital_status,
      address,
      cnpj,
      corporate_name,
      corporate_address,
      rg,
      phone,
    }) => ({
      username,
      bank,
      cpf,
      agency,
      account,
      personal_email,
      nationality,
      marital_status,
      address,
      cnpj,
      corporate_name,
      corporate_address,
      rg,
      phone,
    }))(form);

    Object.keys(pickedForm).map(key => {
      UserForm[pickedForm[key].name] = pickedForm[key].value;

      return key;
    });
  }

  async function handleUpdateForm(event) {
    event.preventDefault();

    handleFormValues(event.target.elements);

    try {
      await api.put(`users/${user.id}`, {
        ...UserForm,
        cpf: cpfDefault(cpfInput),
        cnpj: cnpjDefault(cnpjInput),
      });

      localStorage.setItem(
        '@Bravosul:user',
        JSON.stringify({
          ...user,
          ...UserForm,
          cpf: cpfDefault(cpfInput),
          cnpj: cnpjDefault(cnpjInput),
        }),
      );

      setIsUpdated(true);

      setTimeout(() => {
        setIsUpdated(false);
      }, 1800);
    } catch {
      console.warn('Erro ao atualizar o usuário!');
    }
  }

  function handleClickDelete(boolean) {
    setIsUpdated(boolean);
  }

  const handleResetPassword = useCallback(async () => {
    try {
      const { email } = user;

      await forgotPassword({ email });

      setIsSucessModalOpened(true);

      setTimeout(() => {
        setIsSucessModalOpened(false);
      }, 2500);
    } catch (err) {
      console.log(err);
    }
  }, [forgotPassword, user]);

  function handleCloseClick() {
    setIsSucessModalOpened(false);
  }

  return (
    <>
      <Container variants={USER_DATA_ANIMATION}>
        <ContainerHeader>
          <h1>Meus dados</h1>

          <CgMenuRight onClick={setIsOpened} />
        </ContainerHeader>

        <form onSubmit={handleUpdateForm}>
          <CadastralContainer>
            <CadastralData>
              <h3>Dados Pessoais</h3>

              <CadastralForm>
                <Input
                  type="text"
                  placeholder="Nome Completo"
                  defaultValue={user?.username || ''}
                  name="username"
                />

                <Input
                  type="text"
                  placeholder="RG"
                  name="rg"
                  defaultValue={user.rg || ''}
                />

                <Input
                  onChange={handleCPFInputchange}
                  placeholder="CPF"
                  name="cpf"
                  value={cpfInput}
                />

                <Input
                  type="email"
                  placeholder="E-mail Pessoal"
                  name="personal_email"
                  defaultValue={user.personal_email || ''}
                />

                <Input
                  type="text"
                  placeholder="Telefone"
                  name="phone"
                  defaultValue={user.phone || ''}
                />

                <Input
                  type="text"
                  placeholder="Nacionalidade"
                  name="nationality"
                  defaultValue={user.nationality || ''}
                />

                <Input
                  type="text"
                  placeholder="Estado Civil"
                  name="marital_status"
                  defaultValue={user.marital_status || ''}
                />

                <Input
                  type="text"
                  placeholder="Endereço Pessoal"
                  name="address"
                  defaultValue={user.address || ''}
                />

                <Button type="button" onClick={handleResetPassword}>
                  Resetar senha
                  <BiLock />
                </Button>
              </CadastralForm>
            </CadastralData>

            <CadastralData>
              <h3>Dados Empresariais</h3>
              <small>
                Quem for cooperado, favor colocar os dados da cooperativa*
              </small>

              <CadastralForm>
                <Input
                  onChange={handleCNPJInputChange}
                  placeholder="CNPJ"
                  name="cnpj"
                  value={cnpjInput}
                />

                <Input
                  type="text"
                  placeholder="Razão Social"
                  name="corporate_name"
                  defaultValue={user.corporate_name || ''}
                />

                <Input
                  type="text"
                  placeholder="Endereço Corporativo"
                  name="corporate_address"
                  defaultValue={user.corporate_address || ''}
                />
              </CadastralForm>

              <BankData>
                <h3>Dados Bancários</h3>

                <BankForm>
                  <Input
                    type="text"
                    placeholder="Código do Banco (033)"
                    defaultValue={user.bank || ''}
                    name="bank"
                  />

                  <Input
                    type="text"
                    placeholder="Agência"
                    defaultValue={user.agency || ''}
                    name="agency"
                  />

                  <Input
                    type="text"
                    placeholder="Conta"
                    defaultValue={user.account || ''}
                    name="account"
                  />
                </BankForm>
              </BankData>
            </CadastralData>
          </CadastralContainer>

          <Button type="submit">
            Salvar alterações
            <FiCheck />
          </Button>
        </form>
      </Container>

      {isUpdated && <UpdateUserModal onDeleteToggleClick={handleClickDelete} />}

      {isSuccessModalOpened && (
        <SuccessModal
          handleCloseClick={handleCloseClick}
          hasClose
          message="E-mail de Redefinição Enviado com Sucesso!"
        />
      )}
    </>
  );
};

export default UserData;
