import React, { useEffect, useState, useMemo } from 'react';
import qs from 'qs';
import Pagination from 'react-js-pagination';

import { useAuth } from '../../hooks/AuthContext';

import 'react-day-picker/lib/style.css';

import api from '../../services/api';
import {
  convertHours,
  convertToDate,
  getDifferenceHours,
  isAdmin,
} from '../../utils';

import {
  Container,
  TableHeader,
  ColumnName,
  TableBody,
  TableItem,
  TableField,
  EmptyTable,
} from './styles';

import { APPOINTMENTS_TABLE_ANIMATION } from './animation';

const ReportsTable = ({
  searchQuery,
  startDate,
  endDate,
  updateData,
  selectedProject,
  selectedUser,
  handleReportFiltered,
}) => {
  const { id, projects } = useAuth().user as any;
  const { user } = useAuth() as any;
  const [appointments, setAppointments] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 7;
  const userIsAdmin = user?.roles ? isAdmin(user) : false;

  useEffect(() => {
    filterAppointments();
  }, [searchQuery]);

  useEffect(() => {
    getAppointments();
  }, [startDate, endDate]);

  useEffect(() => {
    let filteredItems = [];

    if (selectedProject && !selectedUser) {
      filteredItems = appointments.filter(
        row => row?.project_id?.name === selectedProject,
      );
    }

    if (selectedUser && !selectedProject) {
      filteredItems = appointments.filter(
        row => row?.user_id?.username === selectedUser,
      );
    }

    if (selectedUser && selectedProject) {
      filteredItems = appointments.filter(
        row =>
          row?.project_id?.name === selectedProject &&
          row?.user_id?.username === selectedUser,
      );
    }

    setFiltered(filteredItems);
    handleReportFiltered(filteredItems);
  }, [selectedProject, selectedUser]);

  async function getAppointments() {
    const queryParams = [{ date_gte: startDate }, { date_lte: endDate }] as any;

    if (!userIsAdmin) {
      queryParams.push({ user_id: id });
    }

    const query = qs.stringify({
      _sort: 'date:asc',
      _where: queryParams,
      _limit: '-1',
    });

    const response = await api.get(`time-entries?${query}`);

    setCurrentPage(1);
    setAppointments(response.data);
    updateData(response.data);
    setFiltered(response.data);
    handleReportFiltered(response.data);
  }

  function filterAppointments() {
    const filteredData = appointments.filter(item => {
      return (
        (item.description ? item.description : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.project_id.id ? findProjectByID(item.project_id.id) : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.date ? item.date : '').toLowerCase().includes(searchQuery) ||
        (item.time_start ? item.time_start : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.time_finish ? item.time_finish : '')
          .toLowerCase()
          .includes(searchQuery)
      );
    });

    setFiltered(filteredData);
  }

  const appointmentsData = useMemo(() => {
    const computedUsers = filtered;

    return computedUsers.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage,
    );
  }, [filtered, currentPage]);

  function findProjectByID(project_id) {
    const project = projects.find(p => {
      return p.id === project_id;
    });

    if (project) {
      return project.name;
    }

    return 'Projeto não atrelado';
  }

  return (
    <>
      <Container variants={APPOINTMENTS_TABLE_ANIMATION}>
        <TableHeader>
          <ColumnName>Nome do projeto</ColumnName>
          <ColumnName>Data</ColumnName>
          <ColumnName>Entrada</ColumnName>
          <ColumnName>Saída</ColumnName>
          <ColumnName>Total</ColumnName>
          {userIsAdmin && (
            <ColumnName isAdmin={userIsAdmin}>Usuário</ColumnName>
          )}
        </TableHeader>
        <TableBody>
          {appointmentsData.length ? (
            appointmentsData.map(appointment => (
              <TableItem key={appointment?.id}>
                <TableField>{appointment?.project_id.name}</TableField>
                <TableField>{convertToDate(appointment?.date)}</TableField>
                <TableField>{convertHours(appointment?.time_start)}</TableField>
                <TableField>
                  {convertHours(appointment?.time_finish)}
                </TableField>
                <TableField isAdmin={userIsAdmin}>
                  {getDifferenceHours(
                    appointment?.time_start,
                    appointment?.time_finish,
                  )}
                </TableField>
                {userIsAdmin && (
                  <TableField isAdmin={userIsAdmin}>
                    {appointment?.user_id?.username}
                  </TableField>
                )}
              </TableItem>
            ))
          ) : (
            <EmptyTable>Nenhum apontamento foi encontrado...</EmptyTable>
          )}
        </TableBody>

        {filtered.length !== 0 && (
          <Pagination
            activePage={currentPage}
            onChange={page => {
              setCurrentPage(page);
            }}
            itemsCountPerPage={7}
            totalItemsCount={filtered.length}
            pageRangeDisplayed={4}
            hideNavigation
          />
        )}
      </Container>
    </>
  );
};

export default ReportsTable;
