/* eslint-disable no-restricted-properties */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';

import { format, parseISO } from 'date-fns';
import { FaFileExcel } from 'react-icons/fa';
import { useAuth } from '../../../../hooks/AuthContext';
import { convertHours, getDifferenceHours, isAdmin } from '../../../../utils';

import { Button } from './styles';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const ExcelExportButton = ({ reportData }) => {
  const { user } = useAuth() as any;
  const userIsAdmin = user?.roles ? isAdmin(user) : false;
  const [userInformations, setUserInformations] = useState({} as unknown);

  function convertExportDate(date) {
    return format(parseISO(date), 'dd/MM/yyyy');
  }

  useEffect(() => {
    const sortedByDate = reportData.sort(
      (a, b) =>
        a.date.localeCompare(b.date) ||
        a.time_start
          .split(':')
          .reverse()
          .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0) -
          b.time_start
            .split(':')
            .reverse()
            .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0),
    );

    setUserInformations(sortedByDate);
  }, [reportData])

  return (
    <ExcelFile
      filename="Relatorio de Apontamentos"
      element={(
        <Button type="button">
          <FaFileExcel />
        </Button>
      )}
    >
      <ExcelSheet data={userInformations} name="Apontamentos">
        <ExcelColumn
          label="Nome do Projeto"
          value={col => col.project_id.name ? col.project_id.name : ''}
        />
        <ExcelColumn
          label="Data"
          value={col => col.date ? convertExportDate(col.date) : ''}
          style={{ fill: { bgColor: { rgb: "a8a3a2" }, fgColor: { rgb: "a8a3a2" } }}}
        />
        <ExcelColumn
          label="Entrada"
          value={col => col.time_start ? convertHours(col.time_start) : ''}
        />
        <ExcelColumn
          label="Saída"
          value={col => col.time_finish ? convertHours(col.time_finish) : ''}
        />
        <ExcelColumn
          label="Total"
          value={col =>
            col.time_finish ?
            getDifferenceHours(col.time_start, col.time_finish) : ''}
        />

        {userIsAdmin ? (
          <ExcelColumn
            label="Usuário"
            value={col => col.user_id?.username || col.user_id?.username}
          />
        ): (
          <ExcelColumn
            label=""
            value=""
          />
        )}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExcelExportButton;
