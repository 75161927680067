/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';

import { VscChromeClose } from 'react-icons/vsc';
import { BiPlus } from 'react-icons/bi';

import { useAuth } from '../../hooks/AuthContext';
import {
  Container,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  Select,
  CustomDatePicker,
} from './styles';

import appointmentsService from '../../services/appointments';

const AppointmentsModal = ({
  isOpened,
  toggleChildOpen,
  type,
  selectedAppointment,
  showTooltip,
}) => {
  const [appointment, setAppointment] = useState({} as any);
  const { projects, id } = useAuth().user as any;

  const formRef = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedProject, setSelectedProject] = useState('');

  function toggleOpen() {
    toggleChildOpen();
  }

  useEffect(() => {
    formRef.current.reset();
    setAppointment({});
    setSelectedProject('');

    if (type === 'Edit' || type === 'View') {
      getAppointment();
    }

    if (type === 'New' && projects?.length === 1) {
      setSelectedProject(projects[0].id);
    }
  }, [toggleChildOpen]);

  async function getAppointment() {
    const appointmentDetails = await appointmentsService.get(
      selectedAppointment,
    );

    if (type === 'Edit' || type === 'View') {
      const { id: selectedProjectId } = appointmentDetails.project_id;

      setSelectedProject(selectedProjectId);
      setStartDate(new Date(`${appointmentDetails.date}T00:00`));
    }

    setAppointment(appointmentDetails);
  }

  function formattedShowTime(time) {
    return time.substring(0, 5);
  }

  function handleFormEvent(event) {
    event.preventDefault();

    if (type === 'New') {
      createAppointment(event);
    }

    if (type === 'Edit') {
      editAppointment(event);
    }
  }

  async function createAppointment(event) {
    try {
      await appointmentsService.create(event.target.elements, id);

      toggleOpen();
    } catch (err: any) {
      showTooltip(err?.message);
    }
  }

  async function editAppointment(event) {
    await appointmentsService.update(
      event.target.elements,
      id,
      selectedAppointment,
    );

    toggleOpen();
  }

  return (
    <Container isOpened={isOpened}>
      <ModalHeader>
        {type === 'New' && <h1>Novo Apontamento</h1>}
        {type === 'Edit' && <h1>Editar Apontamento</h1>}
        {type === 'View' && <h1>Apontamento</h1>}

        <VscChromeClose onClick={toggleOpen} />
      </ModalHeader>

      <ModalBody>
        <form onSubmit={handleFormEvent} ref={formRef}>
          <Select
            name="project"
            placeholder="Projeto"
            required
            onChange={e => setSelectedProject(e.target.value)}
            value={selectedProject}
          >
            <option value="" key="" disabled>
              Selecione um Projeto
            </option>
            {projects?.length &&
              projects?.map(project => (
                <option value={project.id} key={project.id}>
                  {project.name}
                </option>
              ))}
          </Select>

          <Input
            type="text"
            name="description"
            placeholder="Descrição do Apontamento"
            defaultValue={appointment.description || ''}
            readOnly={type === 'View'}
          />

          <CustomDatePicker
            name="date"
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
          />

          <Input
            type="time"
            name="time_start"
            step="0"
            placeholder="Horário de Entrada"
            readOnly={type === 'View'}
            required
            defaultValue={
              appointment.time_start
                ? formattedShowTime(appointment.time_start)
                : ''
            }
          />

          <Input
            type="time"
            name="time_finish"
            placeholder="Horário de Saída"
            readOnly={type === 'View'}
            step="0"
            required
            defaultValue={
              appointment.time_finish
                ? formattedShowTime(appointment.time_finish)
                : ''
            }
          />

          {type === 'New' && (
            <Button type="submit">
              Cadastrar
              <BiPlus />
            </Button>
          )}
          {type === 'Edit' && <Button>Editar Apontamento</Button>}
        </form>
      </ModalBody>
    </Container>
  );
};

export default AppointmentsModal;
