export const COLORS = {
  text: '#282927',
  background: '#F2F2F2',
  primary: '#00B35C',
  white: '#FFF',
  lightGrey: '#F8F8F8',
  danger: '#9B0505',
};

export const DEFAULT_TRANSITION = { type: 'spring', mass: 1.3 };
