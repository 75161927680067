import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 55px 64px 55px;

  width: 100%;
  margin-left: auto;

  @media (min-width: 1129px) {
    width: calc(100% - 300px);
  }

  @media (max-width: 450px) {
    padding: 0 24px;
  }
`;
