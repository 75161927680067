import styled from 'styled-components';
import { motion } from 'framer-motion';

import { COLORS } from '../../../styles/constants';

export const Container = styled(motion.div)`
  padding: 0 55px 64px 55px;

  width: 100%;
  margin-left: auto;

  @media (min-width: 1129px) {
    width: calc(100% - 300px);
  }

  @media (max-width: 450px) {
    padding: 0 24px;
  }

  h1 {
    color: ${COLORS.text};
    font-size: 32px;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;

    @media (max-width: 590px) {
      font-size: 24px;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1128px) {
    flex-direction: column;
  }

  svg {
    font-size: 25px;
    margin-left: 12px;
    margin-bottom: 15px;
    color: ${COLORS.primary};

    @media (min-width: 1128px) {
      display: none;
    }
  }
`;

export const ContainerHeaderTexts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  svg {
    margin: 0;
  }
`;

export const DropdownCalendar = styled.div`
  display: flex;

  @media (max-width: 1128px) {
    width: 100%;
    margin-top: 18px;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  input {
    background: ${COLORS.background};
    border: 1px solid rgba(40, 41, 39, 0.1);
    height: 50px;
    border-radius: 500px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 26px;
    color: ${COLORS.text};
    min-width: 190px;
    appearance: none;

    transition: all 180ms ease-in-out 0s;

    :focus {
      border-color: ${COLORS.primary};
    }

    @media (max-width: 1128px) {
      flex: 1;
      width: unset;
    }

    :last-child {
      margin-left: 12px;

      @media (max-width: 600px) {
        margin: 0;
        margin-top: 18px;
      }
    }

    :hover {
      ::placeholder {
        color: ${COLORS.text};
      }
    }

    ::placeholder {
      transition: all 180ms ease-in-out 0s;
    }

    @media (max-width: 768px) {
      height: 45px;
    }
  }
`;

export const PdfButton = styled.div`
  height: 50px;
  width: 50px;

  border: 1px solid rgba(40, 41, 39, 0.1);
  background-color: transparent;
  font-size: 15px;
  border-radius: 500px;
  opacity: 0.8;

  transition: all 300ms ease-in-out 0s;
  -webkit-transition: all 300ms ease-in-out 0s;
  -moz-transition: all 300ms ease-in-out 0s;
  -ms-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 45px;
    width: 45px;
  }

  &:hover {
    border-color: ${COLORS.primary};

    svg {
      color: ${COLORS.primary};
    }
  }

  svg {
    color: ${COLORS.text};
    margin: 0;
    font-size: 16px;
    display: block;

    transition: all 300ms ease-in-out 0s;
    -webkit-transition: all 300ms ease-in-out 0s;
    -moz-transition: all 300ms ease-in-out 0s;
    -ms-transition: all 300ms ease-in-out 0s;
    -o-transition: all 300ms ease-in-out 0s;
  }

  :last-child {
    margin: 0 16px 0 10px;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 363px) {
    margin-bottom: 18px;
  }
`;

export const FiltersRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  width: 100%;

  @media (max-width: 530px) {
    flex-direction: column;
  }

  small {
    margin-bottom: 6px;
    font-size: 13px;
  }

  select {
    background: #f2f2f2;
    border: 1px solid rgba(40, 41, 39, 0.1);
    min-height: 50px;
    height: 50px;
    border-radius: 500px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 26px;
    color: #282927;
    min-width: 190px;
    flex: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: all 180ms ease-in-out 0s;
    transition: all 180ms ease-in-out 0s;

    @media (max-width: 530px) {
      height: 45px;
      min-height: 45px;
      width: 100%;
    }

    :first-child {
      margin-right: 12px;

      @media (max-width: 530px) {
        margin-bottom: 18px;
      }
    }
  }
`;
