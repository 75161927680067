import React, { useEffect, useState, useMemo } from 'react';

import { FiEye } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import { VscChromeClose } from 'react-icons/vsc';
import Pagination from 'react-js-pagination';

import talentsService from '../../services/talents';

import ExcludeModal from '../ExcludeModal';

import {
  Container,
  TableHeader,
  ColumnName,
  TableBody,
  TableItem,
  TableField,
  EmptyTable,
  ClickableArea,
} from './styles';

import { APPOINTMENTS_TABLE_ANIMATION } from './animations';

const TalentsTable = ({
  searchQuery,
  setModalType,
  setSelectedItem,
  selectedItem,
  handleChange,
}) => {
  const [filtered, setFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [talents, setTalents] = useState([]);

  const itemsPerPage = 5;

  useEffect(() => {
    getTalents();
  }, []);

  useEffect(() => {
    filterAppointments();
  }, [searchQuery]);

  useEffect(() => {
    getTalents();
  }, [handleChange]);

  const talentsData = useMemo(() => {
    const computedUsers = filtered;

    return computedUsers.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage,
    );
  }, [filtered, currentPage]);

  function filterAppointments() {
    const filteredData = talents.filter(item => {
      const skills = [];
      let skillsMatched = false;

      item.skills.map(skill => skills.push(skill.name.toLowerCase()));

      skills.map(skill => {
        if (skill.includes(searchQuery)) {
          skillsMatched = true;
        }

        return skill;
      });

      return (
        (item.name ? item.name : '').toLowerCase().includes(searchQuery) ||
        (item.os ? item.os : '').toLowerCase().includes(searchQuery) ||
        (item.age ? item.age : '').toLowerCase().includes(searchQuery) ||
        (item.city ? item.city : '').toLowerCase().includes(searchQuery) ||
        skillsMatched
      );
    });

    setFiltered(filteredData);
  }

  function handleClickDelete(boolean) {
    setIsDeleteVisible(boolean);
  }

  async function handleConfirmDeleteClick() {
    await talentsService.delete(selectedItem);

    setIsDeleteVisible(false);
    getTalents();
  }

  async function getTalents() {
    const response = await talentsService.get();

    setTalents(response);
    setFiltered(response);
  }

  return (
    <>
      <Container variants={APPOINTMENTS_TABLE_ANIMATION}>
        <TableHeader>
          <ColumnName>Nome</ColumnName>
          <ColumnName>OS</ColumnName>
          <ColumnName>Idade</ColumnName>
          <ColumnName>Cidade</ColumnName>
          <ColumnName>Ações</ColumnName>
        </TableHeader>
        <TableBody>
          {talentsData.length ? (
            talentsData.map(talent => (
              <TableItem key={talent.id}>
                <ClickableArea
                  onClick={() => {
                    setSelectedItem(talent.id);
                    setModalType('View');
                  }}
                >
                  <TableField>{talent.name}</TableField>
                  <TableField>{talent.os}</TableField>
                  <TableField>{talent.age}</TableField>
                  <TableField>{talent.city}</TableField>
                </ClickableArea>
                <TableField>
                  <FiEye
                    onClick={() => {
                      setSelectedItem(talent.id);
                      setModalType('View');
                    }}
                  />
                  <HiOutlinePencil
                    onClick={() => {
                      setSelectedItem(talent.id);
                      setModalType('Edit');
                    }}
                  />
                  <VscChromeClose
                    onClick={() => {
                      setSelectedItem(talent.id);
                      handleClickDelete(talent.id);
                    }}
                  />
                </TableField>
              </TableItem>
            ))
          ) : (
            <EmptyTable>Nenhum talento foi encontrado...</EmptyTable>
          )}
        </TableBody>

        {filtered.length !== 0 && (
          <Pagination
            activePage={currentPage}
            onChange={page => {
              setCurrentPage(page);
            }}
            itemsCountPerPage={5}
            totalItemsCount={filtered.length}
            pageRangeDisplayed={4}
            hideNavigation
          />
        )}
      </Container>

      {isDeleteVisible && (
        <ExcludeModal
          onDeleteToggleClick={handleClickDelete}
          onConfirmDeleteClick={handleConfirmDeleteClick}
        />
      )}
    </>
  );
};

export default TalentsTable;
