import React from 'react';
import { BiCheck } from 'react-icons/bi';
import { Container, Content, CloseModal, Round } from './styles';

import { EXCLUDE_MODAL_ANIMATION } from './animations';

const UpdateUserModal = ({ onDeleteToggleClick }) => {
  function handleDeleteClick() {
    onDeleteToggleClick(false);
  }

  return (
    <Container>
      <Content
        variants={EXCLUDE_MODAL_ANIMATION}
        initial="unMounted"
        animate="mounted"
        exit="unMounted"
      >
        <Round>
          <BiCheck />
        </Round>

        <h1>Dados atualizados com sucesso!</h1>
        <CloseModal onClick={handleDeleteClick}>Fechar</CloseModal>
      </Content>
    </Container>
  );
};

export default UpdateUserModal;
