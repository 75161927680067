import api from './api';

export default {
  async get() {
    const response = await api.get('jobs');

    return response.data;
  },

  async delete(id) {
    return api.delete(`jobs/${id}`);
  },

  async getById(id) {
    const response = await api.get(`jobs/${id}`);

    return response.data;
  },

  async getCustomers() {
    const response = await api.get(`customers`);

    return response.data;
  },

  async getSkills() {
    const response = await api.get(`skills`);

    return response.data;
  },
};
