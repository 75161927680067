import React from 'react';
import { Container, Content, ExcludeUser, CloseModal } from './styles';

import Delete from '../../assets/Delete.svg';
import { EXCLUDE_MODAL_ANIMATION } from './animations';

const ExcludeModal = ({ onDeleteToggleClick, onConfirmDeleteClick }) => {
  function handleDeleteClick() {
    onDeleteToggleClick(false);
  }

  function handleConfirmDeleteClick() {
    onConfirmDeleteClick(true);
  }

  return (
    <Container>
      <Content
        variants={EXCLUDE_MODAL_ANIMATION}
        initial="unMounted"
        animate="mounted"
        exit="unMounted"
      >
        <img src={Delete} alt="" />
        <h1>Deseja realmente excluir esse item?</h1>
        <ExcludeUser onClick={handleConfirmDeleteClick}>
          Excluir Permanentemente
        </ExcludeUser>
        <CloseModal onClick={handleDeleteClick}>Fechar</CloseModal>
      </Content>
    </Container>
  );
};

export default ExcludeModal;
