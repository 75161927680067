import React, { useState } from 'react';

// import Home from './Home';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// import Users from './Users';
import Appointments from './Appointments';
import UserData from './UserData';
import Reports from './Reports';
import Opportunities from './Opportunities';
import Talents from './Talents';

import Sidebar from '../../components/Sidebar';

import { Container } from './styles';
import { useAuth } from '../../hooks/AuthContext';

import { DASHBOARD_ANIMATION } from './animations';

import { isAdmin } from '../../utils';

const Dashboard: React.FC = () => {
  const { path } = useRouteMatch();
  const { user } = useAuth() as any;
  const [isOpened, setIsOpened] = useState(false);
  const userIsAdmin = user.roles ? isAdmin(user) : false;

  function handleIsOpen() {
    setIsOpened(!isOpened);
  }

  return (
    <Container
      variants={DASHBOARD_ANIMATION}
      initial="unMounted"
      animate="mounted"
      exit="unMounted"
      transition={{ duration: 1.5 }}
    >
      <Sidebar isOpened={isOpened} closeSidebar={handleIsOpen} />

      <Switch>
        {!userIsAdmin && (
          <Route exact path={path}>
            <Appointments setIsOpened={handleIsOpen} />
          </Route>
        )}

        <Route exact path={`${path}/meus-dados`}>
          <UserData setIsOpened={handleIsOpen} />
        </Route>

        <Route exact path={`${path}/relatorios`}>
          <Reports setIsOpened={handleIsOpen} />
        </Route>

        <Route exact path={`${path}/oportunidades`}>
          <Opportunities setIsOpened={handleIsOpen} />
        </Route>

        <Route exact path={`${path}/talentos`}>
          <Talents setIsOpened={handleIsOpen} />
        </Route>
      </Switch>
    </Container>
  );
};

export default Dashboard;
