import React from 'react';

import { BiSearch } from 'react-icons/bi';
import { CgMenuRight } from 'react-icons/cg';

import {
  Container,
  MainHeaderTexts,
  HeaderButton,
  SearchInput,
  BottomHeader,
} from './styles';

import { MAIN_HEADER_ANIMATION } from './animations';

const MainHeader = ({
  onNewClick,
  title,
  setIsOpened,
  handleSearchQuery,
  buttonTitle,
}) => {
  function handleSearch(event) {
    handleSearchQuery(event.target.value);
  }

  return (
    <Container variants={MAIN_HEADER_ANIMATION}>
      <MainHeaderTexts>
        <h1>{title}</h1>
        <HeaderButton
          onClick={() => {
            onNewClick();
          }}
        >
          {buttonTitle}
        </HeaderButton>

        <CgMenuRight onClick={setIsOpened} />
      </MainHeaderTexts>

      <BottomHeader>
        <SearchInput>
          <BiSearch />
          <input type="text" placeholder="Pesquisar" onChange={handleSearch} />
        </SearchInput>
      </BottomHeader>
    </Container>
  );
};

export default MainHeader;
