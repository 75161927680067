import React, { useState } from 'react';

import { format, startOfMonth } from 'date-fns';
import { AnimatePresence } from 'framer-motion';

import MainHeader from '../../../components/MainHeader';
import AppointmentsTable from '../../../components/AppointmentsTable';
import AppointmentsModal from '../../../components/AppointmentsModal';
import Tooltip from '../../../components/Tooltip';

import { Container } from './styles';

const Appointments = ({ setIsOpened }) => {
  const [isOpened, setIsOpenend] = useState(false);
  const [isModalType, setModalType] = useState('New');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [updateAppointments, setUpdateAppointments] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [visibleTooltip, setVisibleTooltip] = useState(false);

  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  function handleNewClick() {
    setIsOpenend(!isOpened);
    setUpdateAppointments(!updateAppointments);
  }

  function handleSearchQuery(query) {
    setSearchQuery(query.toLowerCase().trim());
  }

  function handleSelectedAppointment(appointment) {
    setSelectedAppointment(appointment);
  }

  function updateStartDate(value) {
    setStartDate(value);
  }

  function updateEndDate(value) {
    setEndDate(value);
  }

  function toggleTooltip() {
    setVisibleTooltip(!visibleTooltip);
  }

  function showTooltip(message) {
    setMessageError(message);

    toggleTooltip();

    setTimeout(() => {
      setVisibleTooltip(false);
    }, 3500);
  }

  return (
    <>
      <Container>
        <MainHeader
          onNewClick={handleNewClick}
          headerType="Apontamento"
          title="Apontamentos"
          setModalType={() => {
            setModalType('New');
          }}
          setIsOpened={setIsOpened}
          handleSearchQuery={handleSearchQuery}
          toggleStartDate={updateStartDate}
          toggleEndDate={updateEndDate}
        />

        <AppointmentsTable
          setModalType={setModalType}
          isOpened={isOpened}
          toggleChildOpen={handleNewClick}
          searchQuery={searchQuery}
          setSelectedAppointment={handleSelectedAppointment}
          selectedAppointment={selectedAppointment}
          updateAppointments={updateAppointments}
          startDate={startDate}
          endDate={endDate}
        />
      </Container>

      <AppointmentsModal
        isOpened={isOpened}
        toggleChildOpen={handleNewClick}
        type={isModalType}
        selectedAppointment={selectedAppointment}
        showTooltip={showTooltip}
      />

      <AnimatePresence>
        {visibleTooltip && (
          <Tooltip message={messageError} toggleTooltip={toggleTooltip} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Appointments;
