import { DEFAULT_TRANSITION } from '../../styles/constants';

export const CONTAINER_ANIMATION = {
  unMounted: { opacity: 0, y: -50 },
  mounted: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.5, ...DEFAULT_TRANSITION },
  },
};

export const LOGO_ANIMATION = {
  unMounted: { opacity: 0, y: -50 },
  mounted: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.8, ...DEFAULT_TRANSITION },
  },
};
