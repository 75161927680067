import { createGlobalStyle } from 'styled-components';
import { COLORS } from './constants';

export default createGlobalStyle`
  @font-face {
    font-family: 'Circular-Std-Bold';
    src: url(../fonts/Circular/CircularStd-Bold.otf) format('opentype');
  }
  @font-face {
    font-family: 'Circular-Std-Book';
    src: url(../fonts/Circular/CircularStd-Book.otf) format('opentype');
  }
  @font-face {
    font-family: 'Circular-Std-Medium';
    src: url(../fonts/Circular/CircularStd-Medium.otf) format('opentype');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;

    margin-top: 30px;

    li {
      border: 1px solid rgba(40, 41, 39, 0.3);
      width: 32px;
      height: 32px;
      border-radius: 16px;
      font-size: 16px;
      color: rgba(40, 41, 39, 0.3);
      background-color: transparent;
      margin-left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        color: ${COLORS.white};
        border-color: ${COLORS.primary};
        background-color: ${COLORS.primary};

        a {
          color: ${COLORS.white};
        }
      }

      a {
        font-size: 16px;
        color: rgba(40, 41, 39, 0.3);
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  #skills_required, #skills_wanted {
    > div {
      border: 0;
      padding: 0;
    }

    .chip {
      background: ${COLORS.primary};
    }

    li {
      text-transform: capitalize;
    }

    li.highlight {
      background: ${COLORS.primary};
      color: ${COLORS.white};
    }

    li:hover {
      background: ${COLORS.primary};
      color: ${COLORS.white};
    }
  }

  html {
    font-size: 62.5%; /* 1rem = 10px */
    min-width: 100%;
    min-height: 100vh;

    @media (min-width: 1981px) {
      font-size: 80%;
    };
  }

  body {
    height: auto;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body, #root {
    position: relative;
    background-color: ${COLORS.background};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
  }

  body, input, button, select, textarea {
    font: 1.6rem 'Circular-Std-Book', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.48;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  a {
    text-decoration: none;
    background: none;
    /* font-weight: 700; */
    cursor: pointer;
    border: 0;
    transition: 180ms ease-in-out;
    -webkit-transition: 180ms ease-in-out;
    -moz-transition: 180ms ease-in-out;
    -ms-transition: 180ms ease-in-out;
    -o-transition: 180ms ease-in-out;
  }

  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }

  .select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  .select-search__value {
    position: relative;
    z-index: 1;
  }

  .select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 19px;
    width: 11px;
    height: 10px;
  }

  .select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  .select-search__select {
    background: ${COLORS.background};
    border: 1px solid ${COLORS.primary};
    margin-bottom: 14px;
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 4px;
  }

  /**
  * Options
  */
  .select-search__options {
    list-style: none;
  }

  .select-search__row:not(:first-child) {
    border-top: 1px solid rgba(40,41,39,0.1);;
  }

  .select-search__option,
  .select-search__not-found {
    display: block;
    min-height: 34px;
    height: 100%;
    max-height: 90px;
    width: 100%;
    padding: 8px 16px;
    background: ${COLORS.background};
    color: ${COLORS.text};
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-size: 1em;
  }

  .react-datepicker__header {
    padding-top: 0.8em;
  }

  .react-datepicker__month {
    margin: 0.4em 1em;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
`;
