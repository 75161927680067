import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../styles/constants';

interface StartDateCalendar {
  isFocused: boolean;
}

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    color: ${COLORS.text};
    font-size: 32px;
    margin-bottom: 0;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;

    @media (max-width: 450px) {
      font-size: 24px;
    }

    @media (max-width: 590px) {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const MainHeaderTexts = styled.div`
  margin-bottom: 30px;

  display: flex;
  align-items: center;

  h1 {
    @media (max-width: 354px) {
      display: none;
    }
  }

  svg {
    font-size: 25px;
    margin-left: 12px;
    color: ${COLORS.primary};

    @media (min-width: 1128px) {
      display: none;
    }
  }

  @media (max-width: 450px) {
    margin-bottom: 21px;
  }
`;

export const HeaderButton = styled.button`
  background-color: ${COLORS.primary};
  border-radius: 500px;
  padding: 0 38px;
  font-size: 15px;
  height: 50px;
  margin-left: auto;
  color: ${COLORS.white};

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  transition: all 180ms ease-in-out 0s;

  @media (max-width: 450px) {
    font-size: 14px;
    height: 40px;
    padding: 0 20px;
  }

  :hover {
    box-shadow: 0px 6px 45px rgba(0, 179, 92, 0.31);
  }

  svg {
    margin-left: 4px;
  }
`;

export const SearchInput = styled.span`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  input {
    border: 0;
    background-color: transparent;
    border: 1px solid rgba(40, 41, 39, 0.1);
    border-radius: 500px;
    padding: 16px 22px 16px 44px;
    height: 50px;
    position: relative;
    flex: 1;
    font-size: 14px;
    color: ${COLORS.text};
    appearance: none;
    margin-right: 30px;

    transition: all 180ms ease-in-out 0s;

    @media (max-width: 768px) {
      margin: 0;
    }

    @media (max-width: 450px) {
      height: 45px;
    }

    :hover {
      ::placeholder {
        color: ${COLORS.text};
      }
    }

    :focus {
      border-color: ${COLORS.primary};
    }

    ::placeholder {
      color: rgba(40, 41, 39, 0.5);

      transition: all 180ms ease-in-out 0s;
    }
  }

  svg {
    position: absolute;
    left: 22px;
    color: rgba(40, 41, 39, 0.5);
  }
`;

export const BottomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CalendarDropdown = styled.div`
  margin-left: 24px;

  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 24px;
    margin-left: auto;
  }
`;

export const LeftCalendar = styled.span`
  svg {
    color: ${COLORS.primary};
    margin-right: 10px;
  }
`;

export const DatesPicker = styled.div<StartDateCalendar>`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    margin-top: 18px;
    width: 100%;
    justify-content: space-between;
  }

  input {
    background: ${COLORS.background};
    border: 1px solid rgba(40, 41, 39, 0.1);
    height: 50px;
    border-radius: 500px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 26px;
    color: ${COLORS.text};
    width: 180px;
    appearance: none;

    transition: all 180ms ease-in-out 0s;

    @media (max-width: 768px) {
      flex: 1;
      width: unset;
    }

    :focus {
      border-color: ${COLORS.primary};
    }

    :first-child {
      margin-right: 12px;
    }

    :hover {
      ::placeholder {
        color: ${COLORS.text};
      }
    }

    ::placeholder {
      color: ${props =>
        props.isFocused ? COLORS.text : 'rgba(40, 41, 39, 0.5)'};

      transition: all 180ms ease-in-out 0s;
    }

    @media (max-width: 768px) {
      height: 45px;
    }
  }
`;
