import React, { useState, useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, Box, Input, Button } from './styles';

import { useAuth } from '../../hooks/AuthContext';

import Logo2 from '../../assets/Logo-2.svg';

import { ANIMATION } from './animations';

import SuccessModal from '../../components/SuccessModal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const privateQueryCode = useQuery().get('code');
  const passwordRef = useRef(null);
  const passwordConfirmationRef = useRef(null);

  const isVisible = true;
  const [hasErrors, setHasErrors] = useState(false);

  const [isSuccessModalOpened, setIsSucessModalOpened] = useState(false);

  const { resetPassword } = useAuth();

  const handleSubmit = useCallback(async () => {
    const password = passwordRef.current.value;
    const confirmPassword = passwordConfirmationRef.current.value;

    if (password !== confirmPassword) {
      setHasErrors(true);

      return;
    }

    try {
      await resetPassword(privateQueryCode, password);

      setIsSucessModalOpened(true);

      setTimeout(() => {
        setIsSucessModalOpened(false);
        history.push('/');
      }, 1800);
    } catch {
      setHasErrors(true);
    }
  }, [privateQueryCode, resetPassword, history]);

  function handleCloseClick() {
    setIsSucessModalOpened(false);
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <Container>
            <Box
              variants={ANIMATION}
              initial="unMounted"
              animate="mounted"
              exit="unMounted"
            >
              <img src={Logo2} alt="Bravosul" />

              <h1>Resetar senha</h1>
              <p>
                Digite no formulário abaixo, a nova senha que deseja utilizar
              </p>

              <Form onSubmit={handleSubmit}>
                <Input
                  type="password"
                  placeholder="Nova senha"
                  required
                  hasErrors={hasErrors}
                  name="identifier"
                  ref={passwordRef}
                />
                <Input
                  type="password"
                  name="password"
                  placeholder="Confirmação de nova senha"
                  required
                  ref={passwordConfirmationRef}
                  hasErrors={hasErrors}
                />

                <Button type="submit">Resetar senha</Button>
              </Form>
            </Box>
          </Container>

          {isSuccessModalOpened && (
            <SuccessModal
              handleCloseClick={handleCloseClick}
              message="Senha alterada com sucesso"
              hasClose={false}
            />
          )}
        </>
      )}
    </AnimatePresence>
  );
};

export default ForgotPassword;
