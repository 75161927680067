/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

function convertHours(time) {
  return time.slice(0, -7);
}

function convertToDate(date) {
  return moment(date).format('DD/MM/YYYY');
}

function getDifferenceHours(date1, date2) {
  const time_start = <any>new Date();
  const time_end = <any>new Date();

  const splittedDate1 = date1.split(':');
  const splittedDate2 = date2.split(':');

  time_start.setHours(splittedDate1[0], splittedDate1[1], splittedDate1[2], 0);
  time_end.setHours(splittedDate2[0], splittedDate2[1], splittedDate2[2], 0);

  const milliseconds = time_end - time_start;

  return new Date(milliseconds).toISOString().slice(11, 16);
}

function formatDate(value) {
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const days = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];

  return `${days[value.getDay()]}, ${value.getDate()} ${
    months[value.getMonth()]
  } ${value.getFullYear()}`;
}

const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

const cpfDefault = item => {
  return item.replace(/[^\w\s]/gi, '');
};

const cnpjMask = value => {
  return value
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

const cnpjDefault = item => {
  return item.replace(/[^\w\s]/gi, '');
};

const isAdmin = user => {
  if (user.roles) {
    const roles = user.roles.find(role => {
      return role.name === 'Super Admin';
    });

    return roles.length !== 0;
  }

  return false;
};

const isAdminOrRecrutier = user => {
  if (user.roles) {
    const roles = user.roles.find(role => {
      return role.name === 'Super Admin';
    });

    return roles.length !== 0;
  }

  if (user.role) {
    return user.role.name === 'Recruiter';
  }

  return false;
};

function formatReportHours(date1, date2) {
  const t1 = moment(date1.split('.')[0], 'HH:mm:ss');
  const t2 = moment(date2.split('.')[0], 'HH:mm:ss');

  const difference = moment(t1.diff(t2)).format('HH:mm:ss');

  return difference;
}

function calculateHours(start, end) {
  const tStart = moment(start, 'h:mm');
  const tEnd = moment(end, 'h:mm');

  return moment.duration(tEnd.diff(tStart)).asHours();
}

export {
  convertHours,
  convertToDate,
  getDifferenceHours,
  formatDate,
  cpfMask,
  cpfDefault,
  cnpjMask,
  cnpjDefault,
  isAdmin,
  isAdminOrRecrutier,
  formatReportHours,
  calculateHours,
};
