import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../styles/constants';

interface SideBarProps {
  isOpened: boolean;
}

export const Container = styled(motion.div)<SideBarProps>`
  width: 100%;
  max-width: 300px;
  max-height: 64rem;
  min-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0.5rem 2rem;
  padding: 2.4rem;
  border-radius: 10px;
  background-color: ${COLORS.lightGrey};

  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  min-height: 65rem;

  @media (max-width: 1128px) {
    position: fixed;
    top: 0;
    min-height: 100%;
    z-index: 1000;
    left: -300px;

    transition: all 180ms ease-in-out 0s;

    ${props => (props.isOpened ? 'left: 0px;' : 'left: -300px;')};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px 0;
  font-size: 22px;
  width: 100%;
  max-width: 180px;
  border-bottom: 0.5px solid rgba(40, 41, 39, 0.1);

  font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;

  > img {
    width: 100px;
    margin-bottom: 14px;
  }

  small {
    color: ${COLORS.text};
    opacity: 0.5;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: -6px;
    text-align: center;

    font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;
  }
`;

export const Content = styled.div`
  margin-top: 14px;
  width: 100%;
  max-width: 220px;
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const ListItems = styled.ul`
  a {
    height: 50px;
    padding: 0 0 0 40px;
    border-radius: 500px;
    margin-bottom: 14px;
    font-size: 15px;
    color: ${COLORS.text};
    cursor: pointer;
    border: 1px solid transparent;

    display: flex;
    align-items: center;

    font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

    transition: all 180ms ease-in-out 0s;

    > img {
      margin-right: 20px;
    }

    :hover {
      border-color: rgba(40, 41, 39, 0.1);
    }

    &.active {
      background-color: ${COLORS.primary};
      color: ${COLORS.white};

      :hover {
        border-color: transparent;
      }

      > img {
        filter: brightness(0) invert(1);
      }
    }
  }
`;

export const LogoutButton = styled.button`
  margin: auto auto 0 auto;
  padding: 6px 20px;
  border: 1px solid rgba(40, 41, 39, 0.1);
  border-radius: 500px;
  color: ${COLORS.text};
  font-size: 15px;
  background-color: transparent;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  transition: all 180ms ease-in-out 0s;
`;

export const HeaderLogo = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1128px) {
    flex-direction: column-reverse;
    width: 100%;
  }

  > img {
    width: 140px;
    margin-bottom: 18px;
  }

  > svg {
    color: ${COLORS.primary};
    font-size: 20px;

    @media (max-width: 1128px) {
      margin-left: auto;
      margin-bottom: 12px;
    }

    @media (min-width: 1128px) {
      display: none;
    }
  }
`;

export const UsernameContainer = styled.span`
  display: flex;
`;

export const Name = styled.span`
  margin-left: 5px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
