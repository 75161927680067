import styled, { keyframes } from 'styled-components';

export const Overlay = styled.div`
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  place-items: center;
  background-color: rgba(255, 255, 255, 0.7);

  &.active {
    display: grid;
  }
`;

const steamLarge = keyframes`
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
`;

const steamSmall = keyframes`
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
`;

export const LoaderSVG = styled.svg`
  z-index: 2;
  #steamL {
    stroke-dasharray: 13;
    stroke-dashoffset: 13;
    animation: ${steamLarge} 2s infinite;
  }
  #steamR {
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: ${steamSmall} 2s infinite;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  top: 57%;
  right: 50%;
  margin-left: 42px;
  transform: translate(-50%, -50%);
`;
