import styled from 'styled-components';
import { motion } from 'framer-motion';

import { COLORS } from '../../../styles/constants';

export const Container = styled(motion.div)`
  padding: 0 55px 64px 55px;

  width: 100%;
  margin-left: auto;

  @media (min-width: 1129px) {
    width: calc(100% - 300px);
  }

  @media (max-width: 450px) {
    padding: 0 24px;
  }

  h1 {
    color: ${COLORS.text};
    font-size: 32px;
    margin-bottom: 15px;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;

    @media (max-width: 590px) {
      font-size: 24px;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 25px;
    margin-left: 12px;
    margin-bottom: 15px;
    color: ${COLORS.primary};

    @media (min-width: 1128px) {
      display: none;
    }
  }
`;
