import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { COLORS } from '../../styles/constants';

interface UserModalProps {
  isOpened: boolean;
}

export const Container = styled.div<UserModalProps>`
  background-color: ${COLORS.white};
  width: 100%;
  max-width: 469px;
  min-height: 100%;
  border-radius: 20px 0px 0px 20px;
  padding: 47px 50px;
  box-shadow: 0px -4px 172px rgba(0, 0, 0, 0.08);

  position: fixed;
  right: 0;
  top: 0;
  transform: ${props =>
    props.isOpened ? 'translateX(0)' : 'translateX(469px)'};

  transition: all 180ms ease-in-out 0s;

  h1 {
    font-size: 26px;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
    font-size: 24px;
    color: ${COLORS.primary};
  }
`;

export const Input = styled.input`
  border: 1px solid rgba(40, 41, 39, 0.1);
  border-radius: 50px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 22px;
  font-size: 14px;
  appearance: none;
  width: 100%;

  transition: all 180ms ease-in-out 0s;

  :focus {
    border-color: ${COLORS.text};
  }

  :hover {
    ::placeholder {
      color: ${COLORS.text};
    }
  }

  ::placeholder {
    :focus {
      color: ${COLORS.text};
    }
  }
`;

export const CustomDatePicker = styled(DatePicker)``;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 27px;

  form {
    width: 100%;

    input {
      border: 1px solid rgba(40, 41, 39, 0.1);
      border-radius: 50px;
      height: 50px;
      margin-bottom: 20px;
      padding: 0 22px;
      font-size: 14px;
      appearance: none;
      width: 100%;

      transition: all 180ms ease-in-out 0s;

      :focus {
        border-color: ${COLORS.text};
      }

      :hover {
        ::placeholder {
          color: ${COLORS.text};
        }
      }

      ::placeholder {
        :focus {
          color: ${COLORS.text};
        }
      }
    }
  }
`;

export const Select = styled.select`
  border: 1px solid rgba(40, 41, 39, 0.1);
  border-radius: 50px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 22px;
  font-size: 14px;
  appearance: none;
  width: 100%;

  transition: all 180ms ease-in-out 0s;

  :focus {
    border-color: ${COLORS.text};
  }

  :hover {
    ::placeholder {
      color: ${COLORS.text};
    }
  }

  ::placeholder {
    :focus {
      color: ${COLORS.text};
    }
  }
`;

export const Button = styled.button`
  margin-top: 24px;
  margin-left: auto;
  max-width: 262px;
  width: 100%;
  height: 50px;
  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  border-radius: 500px;

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  transition: all 180ms ease-in-out 0s;

  :hover {
    box-shadow: 0px 6px 45px rgba(0, 179, 92, 0.31);
  }

  svg {
    margin-left: 4px;
  }
`;
