import { DEFAULT_TRANSITION } from '../../styles/constants';

export const APPOINTMENTS_TABLE_ANIMATION = {
  unMounted: { opacity: 0, x: 30 },
  mounted: {
    opacity: 1,
    x: 0,
    transition: { delay: 1.4, ...DEFAULT_TRANSITION },
  },
};
