import React, { useEffect, useState, useMemo } from 'react';
import qs from 'qs';
import Pagination from 'react-js-pagination';

import { FiEye } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import { VscChromeClose } from 'react-icons/vsc';

import { useAuth } from '../../hooks/AuthContext';

import 'react-day-picker/lib/style.css';

import ExcludeModal from '../ExcludeModal';

import api from '../../services/api';
import { convertHours, convertToDate, getDifferenceHours } from '../../utils';

import {
  Container,
  TableHeader,
  ColumnName,
  TableBody,
  TableItem,
  TableField,
  EmptyTable,
} from './styles';

import { APPOINTMENTS_TABLE_ANIMATION } from './animations';

const AppointmentsTable = ({
  setModalType,
  isOpened,
  toggleChildOpen,
  searchQuery,
  setSelectedAppointment,
  selectedAppointment,
  updateAppointments,
  startDate,
  endDate,
}) => {
  const { id, projects } = useAuth().user as any;
  const [appointments, setAppointments] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isDeleteUserVisible, setIsDeleteUserVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  useEffect(() => {
    filterAppointments();
  }, [searchQuery]);

  useEffect(() => {
    getAppointments();
  }, [updateAppointments, startDate, endDate]);

  async function getAppointments() {
    const query = qs.stringify({
      _where: [{ date_gte: startDate }, { date_lte: endDate, user_id: id }],
      _sort: 'date:desc',
      _limit: '-1',
    });

    const response = await api.get(`time-entries?${query}`);

    if (response.data.length <= 7) {
      setCurrentPage(1);
    }

    setAppointments(response.data);
    setFiltered(response.data);
  }

  function filterAppointments() {
    const filteredData = appointments.filter(item => {
      return (
        (item.description ? item.description : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.project_id.id ? findProjectByID(item.project_id.id) : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.date ? item.date : '').toLowerCase().includes(searchQuery) ||
        (item.time_start ? item.time_start : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.time_finish ? item.time_finish : '')
          .toLowerCase()
          .includes(searchQuery)
      );
    });

    setFiltered(filteredData);
  }

  const appointmentsData = useMemo(() => {
    const computedUsers = filtered;

    return computedUsers.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage,
    );
  }, [filtered, currentPage]);

  function handleClickDelete(boolean) {
    setIsDeleteUserVisible(boolean);
  }

  async function handleConfirmDeleteClick() {
    await api.delete(`time-entries/${selectedAppointment}`);

    setIsDeleteUserVisible(false);

    getAppointments();
  }

  function findProjectByID(project_id) {
    const project = projects.find(p => {
      return p.id === project_id;
    });

    if (project) {
      return project.name;
    }

    return 'Projeto não atrelado';
  }

  return (
    <>
      <Container variants={APPOINTMENTS_TABLE_ANIMATION}>
        <TableHeader>
          <ColumnName>Nome do projeto</ColumnName>
          <ColumnName>Data</ColumnName>
          <ColumnName>Entrada</ColumnName>
          <ColumnName>Saída</ColumnName>
          <ColumnName>Total</ColumnName>
          <ColumnName>Ações</ColumnName>
        </TableHeader>
        <TableBody>
          {appointmentsData.length ? (
            appointmentsData.map(appointment => (
              <TableItem key={appointment.id}>
                <TableField>
                  {findProjectByID(appointment.project_id.id)}
                </TableField>
                <TableField>{convertToDate(appointment.date)}</TableField>
                <TableField>{convertHours(appointment.time_start)}</TableField>
                <TableField>{convertHours(appointment.time_finish)}</TableField>
                <TableField>
                  {getDifferenceHours(
                    appointment.time_start,
                    appointment.time_finish,
                  )}
                </TableField>
                <TableField>
                  <FiEye
                    onClick={() => {
                      setModalType('View');
                      toggleChildOpen(!isOpened);
                      setSelectedAppointment(appointment.id);
                    }}
                  />
                  <HiOutlinePencil
                    onClick={() => {
                      setModalType('Edit');
                      toggleChildOpen(!isOpened);
                      setSelectedAppointment(appointment.id);
                    }}
                  />
                  <VscChromeClose
                    onClick={() => {
                      setIsDeleteUserVisible(true);
                      setSelectedAppointment(appointment.id);
                    }}
                  />
                </TableField>
              </TableItem>
            ))
          ) : (
            <EmptyTable>Nenhum apontamento foi encontrado...</EmptyTable>
          )}
        </TableBody>

        {filtered.length !== 0 && (
          <Pagination
            activePage={currentPage}
            onChange={page => {
              setCurrentPage(page);
            }}
            itemsCountPerPage={7}
            totalItemsCount={filtered.length}
            pageRangeDisplayed={4}
            hideNavigation
          />
        )}
      </Container>

      {isDeleteUserVisible && (
        <ExcludeModal
          onDeleteToggleClick={handleClickDelete}
          onConfirmDeleteClick={handleConfirmDeleteClick}
        />
      )}
    </>
  );
};

export default AppointmentsTable;
