import { DEFAULT_TRANSITION } from '../../styles/constants';

export const EXCLUDE_MODAL_ANIMATION = {
  unMounted: { opacity: 0, y: -50 },
  mounted: {
    opacity: 1,
    y: 0,
    transition: { ...DEFAULT_TRANSITION },
  },
};
