import styled from 'styled-components';

import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  background-color: #ff6961;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;

  position: fixed;
  top: 55px;
  left: 55px;

  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    justify-content: center;
    text-align: center;
  }

  > button {
    background-color: transparent;

    display: flex;
    align-items: center;
    margin-left: 16px;
    color: #fff;

    svg {
      width: 12px;
    }
  }
`;
