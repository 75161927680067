import React, { useState, useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import { AnimatePresence } from 'framer-motion';
import { Container, Box, Input, Button } from './styles';

import { useAuth } from '../../hooks/AuthContext';

import NewLogo from '../../assets/New-Logo.svg';

import { ANIMATION } from './animations';

const SignIn: React.FC = () => {
  const identifierRef = useRef(null);
  const passwordRef = useRef(null);

  const [isVisible, setIsVisible] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const { signIn } = useAuth();

  const handleSubmit = useCallback(async () => {
    try {
      setIsVisible(false);

      await signIn({
        identifier: identifierRef.current.value,
        password: passwordRef.current.value,
      });
    } catch (err) {
      setIsVisible(true);
      setHasErrors(true);
    }
  }, [signIn]);

  return (
    <AnimatePresence>
      {isVisible && (
        <Container>
          <Box
            variants={ANIMATION}
            initial="unMounted"
            animate="mounted"
            exit="unMounted"
          >
            <img src={NewLogo} alt="Bravosul" />

            <h1>Entre em sua conta</h1>
            <p>Ainda não possui uma conta? Contate o administrador.</p>

            <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                placeholder="Seu E-mail"
                required
                hasErrors={hasErrors}
                name="identifier"
                ref={identifierRef}
              />
              <Input
                type="password"
                name="password"
                placeholder="Sua Senha"
                required
                ref={passwordRef}
                hasErrors={hasErrors}
              />

              <Button type="submit">Entrar</Button>
            </Form>
          </Box>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default SignIn;
