import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../styles/constants';

interface TableFieldProps {
  isAdmin?: boolean;
  priority?: string;
}

export const Container = styled(motion.div)`
  width: 100%;
  margin: 40px 0 45px 0;

  @media (max-width: 450px) {
    margin: 24px 0 30px 0;
  }
`;

export const TableHeader = styled.ul`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0 0 0 45px;

  @media (max-width: 600px) {
    padding: 0 0 0 30px;
  }
`;

export const TableBody = styled.div`
  margin-top: 24px;
`;

export const ColumnName = styled.li`
  color: ${COLORS.text};
  font-size: 15px;
  opacity: 0.5;

  flex: 1;

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  :first-child {
    min-width: 260px;
    max-width: 100%;

    @media (max-width: 600px) {
      min-width: unset;
    }
  }

  :last-child {
    @media (max-width: 600px) {
      margin-left: auto;
    }
  }

  @media (max-width: 940px) {
    :not(:first-child):not(:last-child) {
      display: none;
    }
  }
`;

export const TableItem = styled.div`
  background-color: ${COLORS.lightGrey};
  height: 50px;
  padding: 0 60px 0 45px;
  font-size: 14.5px;
  border-radius: 4px;
  color: ${COLORS.text};
  position: relative;
  margin-top: 12px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 0 30px;
  }

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  ::before {
    position: absolute;
    left: 0;
    content: '';
    width: 10px;
    background: rgba(255, 31, 98, 0.2);
    height: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const TableField = styled.div<TableFieldProps>`
  display: flex;
  align-items: center;

  ${props =>
    props.priority === 'Alta' &&
    css`
      color: #9b0505;
      background-color: rgba(189, 0, 0, 0.2);
    `};

  ${props =>
    props.priority === 'Média' &&
    css`
      color: #f47f20;
      background-color: rgba(244, 127, 32, 0.2);
    `};

  ${props =>
    props.priority === 'Baixa' &&
    css`
      color: #059b14;
      background-color: rgba(0, 189, 19, 0.2);
    `};

  :nth-child(4) {
    text-transform: uppercase;
    font-size: 12px;
    max-width: 70px;
    height: 25px;

    display: flex;
    justify-content: center;
    border-radius: 50px;

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;
  }

  flex: 1;

  @media (max-width: 940px) {
    :nth-child(4) {
      display: none !important;
    }
  }

  @media (min-width: 940px) {
    :nth-child(2) {
      margin-right: -50px;
    }

    :last-child {
      margin-right: 20px;
    }

    :nth-child(4) {
      margin-right: 75px;
      margin-left: -44px;
    }

    :first-child {
      margin-right: 60px;
    }
  }

  :first-child {
    margin-right: 10px;
    min-width: 250px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;

    @media (max-width: 600px) {
      min-width: 50px;
      width: 50px;
    }
  }

  ${props =>
    props.isAdmin &&
    css`
      :nth-child(5) {
        margin-right: -38px;
      }

      :nth-child(6) {
        margin-left: 5px;
        max-width: 90px;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}

  @media (max-width: 940px) {
    :not(:first-child):not(:last-child) {
      display: none;
    }
  }

  svg {
    font-size: 17px;
    margin-left: 4.5px;
  }
`;

export const EmptyTable = styled.div`
  color: ${COLORS.text};
  border-radius: 6px;
  min-height: 100px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClickableArea = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
`;
