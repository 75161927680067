/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useHistory,
} from 'react-router-dom';

import jwt from 'jsonwebtoken';
import { useAuth } from '../hooks/AuthContext';

import { isAdmin } from '../utils';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isGuest?: boolean;
  component: React.ComponentType;
  path: string;
  exact?: boolean;
}

const token = localStorage.getItem('@Bravosul:token');

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isGuest = false,
  component: Component,
  ...rest
}) => {
  const { user, signOut } = useAuth() as any;
  const userIsAdmin = user && user.roles ? isAdmin(user) : false;

  const history = useHistory();

  useEffect(() => {
    (async function decode() {
      if (token) {
        const { exp } = await jwt.decode(token);

        if (exp * 1000 < new Date().getTime()) {
          signOut();
          history.push('/');
        }
      }
    })();
  }, []);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user || isGuest ? (
          <Component />
        ) : (
          (userIsAdmin && (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/dashboard/relatorios',
                state: { from: location },
              }}
            />
          )) || (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/dashboard',
                state: { from: location },
              }}
            />
          )
        );
      }}
    />
  );
};

export default Route;
