import React, { useState } from 'react';

import { useAuth } from '../../../hooks/AuthContext';

import { USER_DATA_ANIMATION } from './animations';

import { Container } from './styles';

import { isAdminOrRecrutier } from '../../../utils';

import OpportunitiesTable from '../../../components/OpportunitiesTable';
import DefaultHeader from '../../../components/DefaultHeader';
import OpportunitiesModal from '../../../components/OpportunitiesModal';

const Opportunity = ({ setIsOpened }) => {
  const [isOpened, setIsOpenend] = useState(false);
  const [isModalType, setModalType] = useState('New');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [hasChange, setHasChange] = useState(false);

  const { user } = useAuth() as any;

  const userIsAdminOrRecruiter =
    user.roles || user.role ? isAdminOrRecrutier(user) : false;

  function handleSearchQuery(query) {
    setSearchQuery(query.toLowerCase().trim());
  }

  function handleNewClick() {
    setIsOpenend(!isOpened);
  }

  if (userIsAdminOrRecruiter) {
    return (
      <>
        <Container variants={USER_DATA_ANIMATION}>
          <DefaultHeader
            onNewClick={() => {
              setModalType('New');
              handleNewClick();
            }}
            title="Oportunidades"
            buttonTitle="Nova Oportunidade"
            setIsOpened={setIsOpened}
            handleSearchQuery={handleSearchQuery}
          />

          <OpportunitiesTable
            searchQuery={searchQuery}
            setModalType={type => {
              setModalType(type);
              handleNewClick();
            }}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            handleChange={hasChange}
          />
        </Container>

        <OpportunitiesModal
          modalType={isModalType}
          isOpened={isOpened}
          toggleChildOpen={handleNewClick}
          selectedItem={selectedItem}
          handleChange={() => {
            setHasChange(!hasChange);
          }}
        />
      </>
    );
  }

  return <></>;
};

export default Opportunity;
