/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';

import { CgMenuRight } from 'react-icons/cg';

import { format, startOfMonth } from 'date-fns';

import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
} from '@react-pdf/renderer';

// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';

import { useAuth } from '../../../hooks/AuthContext';

import { REPORTS_ANIMATION } from './animations';

import {
  Container,
  ContainerHeader,
  DropdownCalendar,
  ContainerHeaderTexts,
  SubHeader,
  FiltersRow,
  PdfButton,
} from './styles';

import { isAdmin } from '../../../utils';

import ReportsTable from '../../../components/ReportsTable';
import { LazyDownloadPDFButton } from '../../../components/LazyDownloadPdf';
import ExcelExportButton from './ExcelExportButton';

const Reports = ({ setIsOpened }) => {
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [reportData, setReportData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);

  const [selectedProject, setSelectedProject] = useState('');
  const [selectedUser, setSelectedUser] = useState('');

  const { user } = useAuth() as any;
  const searchQuery = '';
  const userIsAdmin = user?.roles ? isAdmin(user) : false;

  function handleDataUpdate(data) {
    setReportData(data);
    handleProjects(data);
    handleUsers(data);
  }

  function handleReportFiltered(data) {
    setReportData(data);
  }

  function handleProjects(data) {
    const projectsArray = [];

    data.map(item => {
      return projectsArray.push(item?.project_id?.name);
    });

    const unique = projectsArray.filter((v, i, a) => a.indexOf(v) === i);

    setProjects(unique);
  }

  function handleUsers(data) {
    const usersArray = [];

    data.map(item => {
      return usersArray.push(item?.user_id?.username);
    });

    const unique = usersArray.filter((v, i, a) => a.indexOf(v) === i);

    setUsers(unique);
  }

  return (
    <>
      <Container variants={REPORTS_ANIMATION}>
        <ContainerHeader>
          <ContainerHeaderTexts>
            <h1>Relatórios</h1>
            <CgMenuRight onClick={setIsOpened} />
          </ContainerHeaderTexts>

          <DropdownCalendar>
            <SubHeader>
              <ExcelExportButton reportData={reportData} />
              <PdfButton>
                <LazyDownloadPDFButton
                  project={selectedProject}
                  startDate={startDate}
                  endDate={endDate}
                  reportData={reportData}
                />
              </PdfButton>
            </SubHeader>

            <input
              type="date"
              placeholder="Data de Início"
              defaultValue={startDate}
              onChange={event => {
                setStartDate(event.target.value);
              }}
            />

            <input
              type="date"
              placeholder="Data Final"
              defaultValue={endDate}
              onChange={event => {
                setEndDate(event.target.value);
              }}
            />
          </DropdownCalendar>
        </ContainerHeader>

        {userIsAdmin && (
          <FiltersRow>
            <select
              defaultValue=""
              onChange={e => {
                setSelectedProject(e.target.value);
              }}
            >
              <option value="" disabled>
                Projetos
              </option>
              {projects.map(project => {
                return <option key={project?.id}>{project}</option>;
              })}
            </select>

            <select
              defaultValue=""
              onChange={e => {
                setSelectedUser(e.target.value);
              }}
            >
              <option value="" disabled>
                Usuários
              </option>
              {users.map(item => {
                return <option key={item?.id}>{item}</option>;
              })}
            </select>
          </FiltersRow>
        )}

        <ReportsTable
          searchQuery={searchQuery}
          startDate={startDate}
          endDate={endDate}
          updateData={handleDataUpdate}
          handleReportFiltered={handleReportFiltered}
          selectedProject={selectedProject}
          selectedUser={selectedUser}
        />
      </Container>

      {/* <ReportPdf
        reportData={reportData}
        startDate={startDate}
        endDate={endDate}
        project={selectedProject}
      /> */}
    </>
  );
};

export default Reports;
