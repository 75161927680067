import axios from 'axios';
import LoaderService from './loader';

const baseURL = process.env.REACT_APP_BASE_URL;
const loader = new LoaderService();

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  config => {
    loader.switchLoaderClass();
    return config;
  },
  error => {
    loader.switchLoaderClass();
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    loader.switchLoaderClass(false);
    return response;
  },
  error => {
    loader.switchLoaderClass(false);
    return Promise.reject(error);
  },
);

export default api;
