import { DEFAULT_TRANSITION } from '../../../styles/constants';

export const REPORTS_ANIMATION = {
  unMounted: { opacity: 0, x: 30 },
  mounted: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.8, ...DEFAULT_TRANSITION },
  },
};
