import React, { useState } from 'react';

import { BiSearch } from 'react-icons/bi';
import { CgMenuRight } from 'react-icons/cg';

import { format, startOfMonth } from 'date-fns';
import DatePicker from 'react-datepicker';

import {
  Container,
  MainHeaderTexts,
  HeaderButton,
  SearchInput,
  BottomHeader,
  DatesPicker,
} from './styles';

import { MAIN_HEADER_ANIMATION } from './animations';

const MainHeader = ({
  onNewClick,
  setModalType,
  headerType,
  title,
  setIsOpened,
  handleSearchQuery,
  toggleStartDate,
  toggleEndDate,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [initialDateFocused, setInitialDateFocused] = useState(false);

  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const buttonTitle = `Novo ${headerType}`;

  function handleSearch(event) {
    handleSearchQuery(event.target.value);
  }

  function setInitialDateValue(date) {
    toggleStartDate(format(date, 'yyyy-MM-dd'));
  }

  function setEndDateValue(date) {
    toggleEndDate(format(date, 'yyyy-MM-dd'));
  }

  return (
    <Container variants={MAIN_HEADER_ANIMATION}>
      <MainHeaderTexts>
        <h1>{title}</h1>
        <HeaderButton
          onClick={() => {
            onNewClick();
            setModalType();
          }}
        >
          {buttonTitle}
        </HeaderButton>

        <CgMenuRight onClick={setIsOpened} />
      </MainHeaderTexts>

      <BottomHeader>
        <SearchInput
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          isFocused={isFocused}
        >
          <BiSearch />
          <input type="text" placeholder="Pesquisar" onChange={handleSearch} />
        </SearchInput>

        {headerType === 'Apontamento' && (
          <DatesPicker isFocused={initialDateFocused}>
            <DatePicker
              selected={startDate}
              onChange={(date: any) => {
                setInitialDateValue(date);
                setStartDate(date);
              }}
              onFocus={() => setInitialDateFocused(true)}
              onBlur={() => setInitialDateFocused(false)}
              dateFormat="dd/MM/yyyy"
            />

            <DatePicker
              selected={endDate}
              onChange={(date: any) => {
                setEndDateValue(date);
                setEndDate(date);
              }}
              onFocus={() => setInitialDateFocused(true)}
              onBlur={() => setInitialDateFocused(false)}
              dateFormat="dd/MM/yyyy"
            />
          </DatesPicker>
        )}
      </BottomHeader>
    </Container>
  );
};

export default MainHeader;
