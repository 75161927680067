import styled from 'styled-components';
import { COLORS } from '../../../../styles/constants';

export const Button = styled.button`
  height: 50px;
  width: 50px;

  border: 1px solid rgba(40, 41, 39, 0.1);
  background-color: transparent;
  font-size: 15px;
  border-radius: 500px;
  opacity: 0.8;

  transition: all 300ms ease-in-out 0s;
  -webkit-transition: all 300ms ease-in-out 0s;
  -moz-transition: all 300ms ease-in-out 0s;
  -ms-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;

  @media (max-width: 768px) {
    height: 45px;
    width: 45px;
  }

  &:hover {
    border-color: ${COLORS.primary};

    svg {
      color: ${COLORS.primary};
    }
  }

  svg {
    color: ${COLORS.text};
    margin: 0;
    font-size: 16px;
    display: block;

    transition: all 300ms ease-in-out 0s;
    -webkit-transition: all 300ms ease-in-out 0s;
    -moz-transition: all 300ms ease-in-out 0s;
    -ms-transition: all 300ms ease-in-out 0s;
    -o-transition: all 300ms ease-in-out 0s;
  }
`;
