import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../styles/constants';

export const Container = styled(motion.div)`
  background-color: ${COLORS.background};
  max-width: 130rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 4.5rem;

  display: flex;
  flex: 1 1 100%;

  @media (max-width: 450px) {
    margin-top: 30px;
  }

  @media (min-width: 1128px) and (max-width: 1430px) {
    max-width: 120rem;
  }

  @media (min-width: 1430px) and (max-width: 1500px) {
    max-width: 130rem;
  }
`;
