import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';

interface AuthState {
  token: string;
  user: object;
}

interface SignInCredentials {
  identifier: string;
  password: string;
}

interface SignInAdminCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: object;
  signIn(credentials: SignInCredentials): Promise<void>;
  adminSignIn(credentials: SignInAdminCredentials): Promise<void>;
  signOut(): void;
  forgotPassword(email: string): Promise<void>;
  resetPassword(code: string, password: string): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Bravosul:token');
    const user = localStorage.getItem('@Bravosul:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const adminSignIn = useCallback(async ({ email, password }) => {
    try {
      const response = await api.post('admin/login', {
        email,
        password,
      });

      const { token, user } = response.data.data;

      localStorage.setItem('@Bravosul:token', token);
      localStorage.setItem('@Bravosul:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
    } catch {
      throw new Error('Admin User with this credentials does not exists.');
    }
  }, []);

  const signIn = useCallback(async ({ identifier, password }) => {
    try {
      const response = await api.post('auth/local', {
        identifier,
        password,
      });

      const { jwt, user } = response.data;

      localStorage.setItem('@Bravosul:token', jwt);
      localStorage.setItem('@Bravosul:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${jwt}`;

      setData({ token: jwt, user });
    } catch {
      throw new Error('User with this credentials does not exists.');
    }
  }, []);

  const signOut = useCallback(() => {
    delete api.defaults.headers.authorization;

    localStorage.removeItem('@Bravosul:token');
    localStorage.removeItem('@Bravosul:user');

    setData({} as AuthState);
  }, []);

  const forgotPassword = useCallback(async ({ email }) => {
    try {
      await api.post('auth/forgot-password', { email });
    } catch {
      throw new Error('Error sending email with password reset code.');
    }
  }, []);

  const resetPassword = useCallback(async (code, password) => {
    try {
      await api.post('auth/reset-password', {
        code,
        password,
        passwordConfirmation: password,
      });
    } catch {
      throw new Error('The code does not exist or is invalid.');
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        adminSignIn,
        signOut,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
