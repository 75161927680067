export default class LoaderService {
  timeToLeaveLoader = process.env.TIME_TO_LEAVE_LOADER || 1500;

  timeoutLoader: number;

  switchLoaderClass(active = true): void {
    clearTimeout(this.timeoutLoader);
    const loader = document.querySelector('.overlay-loading');
    if (active) {
      loader.classList.add('active');
    } else {
      this.timeoutLoader = setTimeout(
        () => loader.classList.remove('active'),
        this.timeToLeaveLoader,
      );
    }
  }
}
