import React, { useEffect, useState, useMemo } from 'react';

import { FiEye } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import { VscChromeClose } from 'react-icons/vsc';
import Pagination from 'react-js-pagination';

import opportunitiesService from '../../services/jobs';

import ExcludeModal from '../ExcludeModal';

import {
  Container,
  TableHeader,
  ColumnName,
  TableBody,
  TableItem,
  TableField,
  EmptyTable,
  ClickableArea,
} from './styles';

import { APPOINTMENTS_TABLE_ANIMATION } from './animations';

const OpportunitiesTable = ({
  searchQuery,
  setModalType,
  setSelectedItem,
  selectedItem,
  handleChange,
}) => {
  const [filtered, setFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [opportunities, setOpportunities] = useState([]);

  const itemsPerPage = 5;

  useEffect(() => {
    getOpportunities();
  }, [handleChange]);

  useEffect(() => {
    filterAppointments();
  }, [searchQuery]);

  function filterAppointments() {
    const filteredData = opportunities.filter(item => {
      return (
        (item.description ? item.description : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.experience_level ? item.experience_level : '')
          .toLowerCase()
          .includes(searchQuery) ||
        (item.priority ? item.priority : '').toLowerCase().includes(searchQuery)
      );
    });

    setFiltered(filteredData);
  }

  function handleClickDelete(boolean) {
    setIsDeleteVisible(boolean);
  }

  async function handleConfirmDeleteClick() {
    await opportunitiesService.delete(selectedItem);

    setIsDeleteVisible(false);
    getOpportunities();
  }

  async function getOpportunities() {
    const response = await opportunitiesService.get();

    const orderedOpportunities = response.sort((a, b) => {
      if (a.priority === 'Alta' && b.priority === 'Média') return -1;
      if (a.priority === 'Alta' && b.priority === 'Baixa') return -1;
      if (a.priority === 'Média' && b.priority === 'Alta') return 1;
      if (a.priority === 'Média' && b.priority === 'Baixa') return -1;
      if (a.priority === 'Baixa' && b.priority === 'Alta') return 1;
      if (a.priority === 'Baixa' && b.priority === 'Média') return 1;

      return -1;
    });

    setOpportunities(orderedOpportunities);
    setFiltered(orderedOpportunities);
  }

  const opportunitiesData = useMemo(() => {
    const computedUsers = filtered;

    return computedUsers.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage,
    );
  }, [filtered, currentPage]);

  return (
    <>
      <Container variants={APPOINTMENTS_TABLE_ANIMATION}>
        <TableHeader>
          <ColumnName>Descrição</ColumnName>
          <ColumnName>Cliente</ColumnName>
          <ColumnName>Nível de XP</ColumnName>
          <ColumnName>Prioridade</ColumnName>
          <ColumnName>Ações</ColumnName>
        </TableHeader>
        <TableBody>
          {opportunitiesData.length ? (
            opportunitiesData.map(item => (
              <TableItem key={item.id}>
                <ClickableArea
                  onClick={() => {
                    setSelectedItem(item.id);
                    setModalType('View');
                  }}
                >
                  <TableField>{item.description}</TableField>
                  <TableField>
                    {item.customer ? item.customer.name : ''}
                  </TableField>
                  <TableField>{item.experience_level}</TableField>
                  <TableField priority={item.priority}>
                    {item.priority}
                  </TableField>
                </ClickableArea>
                <TableField>
                  <FiEye
                    onClick={() => {
                      setSelectedItem(item.id);
                      setModalType('View');
                    }}
                  />
                  <HiOutlinePencil
                    onClick={() => {
                      setSelectedItem(item.id);
                      setModalType('Edit');
                    }}
                  />
                  <VscChromeClose
                    onClick={() => {
                      setSelectedItem(item.id);
                      handleClickDelete(true);
                    }}
                  />
                </TableField>
              </TableItem>
            ))
          ) : (
            <EmptyTable>Nenhuma oportunidade foi encontrada...</EmptyTable>
          )}
        </TableBody>

        {filtered.length !== 0 && (
          <Pagination
            activePage={currentPage}
            onChange={page => {
              setCurrentPage(page);
            }}
            itemsCountPerPage={5}
            totalItemsCount={filtered.length}
            pageRangeDisplayed={4}
            hideNavigation
          />
        )}
      </Container>

      {isDeleteVisible && (
        <ExcludeModal
          onDeleteToggleClick={handleClickDelete}
          onConfirmDeleteClick={handleConfirmDeleteClick}
        />
      )}
    </>
  );
};

export default OpportunitiesTable;
