import api from './api';

export default {
  async get() {
    const response = await api.get('talents');

    return response.data;
  },

  async delete(id) {
    return api.delete(`talents/${id}`);
  },

  async getById(id) {
    const response = await api.get(`talents/${id}`);

    return response.data;
  },

  async getCustomers() {
    const response = await api.get(`customers`);

    return response.data;
  },

  async getSkills() {
    const response = await api.get(`skills`);

    return response.data;
  },
};
