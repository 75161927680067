/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';

import { VscChromeClose } from 'react-icons/vsc';
import { BiPlus } from 'react-icons/bi';

import { Multiselect } from 'multiselect-react-dropdown';

import {
  Container,
  ModalHeader,
  ModalBody,
  // Input,
  Button,
  Select,
  FormRow,
  TextArea,
} from './styles';

import opportunitiesService from '../../services/jobs';

import api from '../../services/api';

const AppointmentsModal = ({
  isOpened,
  toggleChildOpen,
  modalType = 'New',
  selectedItem,
  handleChange,
}) => {
  const formRef = useRef(null);

  const [opportunity, setOpportunity] = useState({} as any);
  const [customers, setCustomers] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkillsWanted, setSelectedSkillsWanted] = useState([]);
  const [selectedSkillsRequired, setSelectedSkillsRequired] = useState([]);
  const [experienceLevel, setExperienceLevel] = useState('');
  const [priority, setPriority] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(0 as any);

  function toggleOpen() {
    toggleChildOpen();
  }

  useEffect(() => {
    formRef.current.reset();
    getSkills();
    getCustomers();
    setOpportunity({});
    setSelectedCustomer(0);
    setExperienceLevel('');
    setPriority('');
    setSelectedSkillsRequired([]);
    setSelectedSkillsWanted([]);

    if (modalType === 'Edit' || modalType === 'View') {
      getOpportunity();
    }
  }, [toggleChildOpen]);

  async function getOpportunity() {
    const opportunityDetails = await opportunitiesService.getById(selectedItem);

    setSelectedCustomer(opportunityDetails.customer);
    setExperienceLevel(opportunityDetails.experience_level);
    setPriority(opportunityDetails.priority);
    setSelectedSkillsWanted(opportunityDetails.skills_wanted);
    setSelectedSkillsRequired(opportunityDetails.skills_required);

    setOpportunity(opportunityDetails);
  }

  async function getCustomers() {
    const customersList = await opportunitiesService.getCustomers();

    setCustomers(customersList);
  }

  async function getSkills() {
    const skillsList = await opportunitiesService.getSkills();

    setSkills(skillsList);
  }

  function handleFormEvent(event) {
    event.preventDefault();

    if (modalType === 'New') {
      createOpportunities(event);
    }

    if (modalType === 'Edit') {
      editOpportunity(event);
    }
  }

  function onSelectSkillWanted(selectedList) {
    setSelectedSkillsWanted(selectedList);
  }

  function onRemoveSkillWanted(selectedList) {
    setSelectedSkillsWanted(selectedList);
  }

  function onSelectSkillRequired(selectedList) {
    setSelectedSkillsRequired(selectedList);
  }

  function onRemoveSkillRequired(selectedList) {
    setSelectedSkillsRequired(selectedList);
  }

  async function createOpportunities(event) {
    const { description } = event.target.elements;

    const selectedSkillsWantedIds = [];
    const selectedSkillRequiredIds = [];

    if (selectedSkillsWanted.length) {
      selectedSkillsWanted.map(item => {
        return selectedSkillsWantedIds.push(item.id);
      });
    }

    if (selectedSkillsRequired.length) {
      selectedSkillsRequired.map(item => {
        return selectedSkillRequiredIds.push(item.id);
      });
    }

    await api.post('jobs', {
      description: description.value,
      experience_level: experienceLevel,
      priority,
      customer: selectedCustomer,
      skills_required: selectedSkillRequiredIds,
      skills_wanted: selectedSkillsWantedIds,
    });

    handleChange();
    toggleOpen();
  }

  async function editOpportunity(event) {
    const { description } = event.target.elements;

    const selectedSkillsWantedIds = [];
    const selectedSkillRequiredIds = [];

    if (selectedSkillsWanted.length) {
      selectedSkillsWanted.map(item => {
        return selectedSkillsWantedIds.push(item.id);
      });
    }

    if (selectedSkillsRequired.length) {
      selectedSkillsRequired.map(item => {
        return selectedSkillRequiredIds.push(item.id);
      });
    }

    await api.put(`jobs/${selectedItem}`, {
      description: description.value,
      experience_level: experienceLevel,
      priority,
      customer: selectedCustomer,
      skills_required: selectedSkillRequiredIds,
      skills_wanted: selectedSkillsWantedIds,
    });

    handleChange();
    toggleOpen();
  }

  return (
    <Container isOpened={isOpened}>
      <ModalHeader>
        {modalType === 'New' && <h1>Nova Oportunidade</h1>}
        {modalType === 'Edit' && <h1>Editar Oportunidade</h1>}
        {modalType === 'View' && <h1>Oportunidade</h1>}

        <VscChromeClose onClick={toggleOpen} />
      </ModalHeader>

      <ModalBody>
        <form onSubmit={handleFormEvent} ref={formRef}>
          <FormRow>
            <label>Conhecimentos Técnicos</label>
            <Multiselect
              options={skills}
              displayValue="name"
              name="skills_required"
              id="skills_required"
              placeholder="Conhecimentos Técnicos"
              emptyRecordMsg="Nenhuma opção encontrada"
              selectedValues={selectedSkillsRequired}
              groupBy="type"
              onSelect={onSelectSkillRequired}
              onRemove={onRemoveSkillRequired}
              disable={modalType === 'View'}
              required
            />

            <label>Conhecimentos Técnicos Desejáveis</label>
            <Multiselect
              options={skills}
              displayValue="name"
              id="skills_wanted"
              name="skills_wanted"
              placeholder="Conhecimentos Técnicos Desejáveis"
              emptyRecordMsg="Nenhuma opção encontrada"
              groupBy="type"
              onSelect={onSelectSkillWanted}
              onRemove={onRemoveSkillWanted}
              disable={modalType === 'View'}
              selectedValues={selectedSkillsWanted}
              required
            />

            <label>Descrição da Oportunidade</label>
            <TextArea
              id="description"
              name="description"
              placeholder="Descrição da Oportunidade"
              defaultValue={opportunity.description || ''}
              disabled={modalType === 'View'}
              required
            />
          </FormRow>

          <FormRow>
            <label>Nível de Experiência</label>
            <Select
              name="experience_level"
              placeholder="Nível de Experiência"
              value={experienceLevel}
              required
              disabled={modalType === 'View'}
              onChange={value => setExperienceLevel(value.target.value)}
            >
              <option value="" disabled>
                Nível de Experiência
              </option>
              <option value="Junior">Junior</option>
              <option value="Pleno">Pleno</option>
              <option value="Sênior">Sênior</option>
              <option value="Especialista">Especialista</option>
            </Select>

            <label>Prioridade</label>
            <Select
              name="priority"
              placeholder="Prioridade"
              value={priority}
              required
              disabled={modalType === 'View'}
              onChange={value => setPriority(value.target.value)}
            >
              <option value="" disabled>
                Prioridade
              </option>
              <option value="Alta">Alta</option>
              <option value="Média">Média</option>
              <option value="Baixa">Baixa</option>
            </Select>

            <label>Cliente</label>
            <Select
              name="customer"
              placeholder="Cliente"
              value={selectedCustomer.id}
              disabled={modalType === 'View'}
              onChange={value => {
                setSelectedCustomer(value.target.value);
              }}
            >
              <option value="0">Cliente</option>
              {customers.map(customer => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </Select>

            {modalType === 'New' && (
              <Button type="submit">
                Cadastrar Oportunidade
                <BiPlus />
              </Button>
            )}
            {modalType === 'Edit' && <Button>Editar Oportunidade</Button>}
          </FormRow>
        </form>
      </ModalBody>
    </Container>
  );
};

export default AppointmentsModal;
