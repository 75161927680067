/* eslint-disable no-restricted-properties */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { pdf, Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { format, parseISO } from 'date-fns';
import moment from 'moment';

import { FaFilePdf } from 'react-icons/fa';
import { convertHours, convertToDate, getDifferenceHours } from '../../utils';

import styles from './styles';
import NewLogo from '../../assets/NewLogo.png';

interface DocumentPdfInterface {
  project: string;
  startDate: string;
  endDate: string;
  userInformations: unknown;
}

const DocumentPdf = ({
  project,
  startDate,
  endDate,
  userInformations,
}: DocumentPdfInterface) => {
  function convertDateToPeriod(date) {
    return format(parseISO(date), 'dd/MM/yyyy');
  }

  function msToTime(s) {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    return `${hrs < 10 ? `0${hrs}` : hrs}:${mins < 10 ? `0${mins}` : mins}:${
      secs < 10 ? `0${secs}` : secs
    }`;
  }

  function sumHours(time) {
    const hourCount = [];

    time[1].map(hour => {
      hourCount.push(getDifferenceHours(hour.time_start, hour.time_finish));

      return hour;
    });

    const sum = hourCount.reduce(
      (acc, info) => acc.add(moment.duration(info)),
      moment.duration(),
    );

    const milliseconds = sum.valueOf();

    return msToTime(milliseconds);
  }

  function isWeekend(date) {
    const isWeekDay = parseISO(date).getDay();

    return isWeekDay === 6 || isWeekDay === 0;
  }

  return (
    <Document>
      <Page size="A4" style={styles.section}>
        <View style={styles.home}>
          <Image src={NewLogo} style={styles.image} />
          <Text style={styles.title}>Relatório de horas</Text>
          <Text style={styles.subtitle}>
            Período: {convertDateToPeriod(startDate)} até{' '}
            {convertDateToPeriod(endDate)}
          </Text>
          <Text style={styles.project}>{project || project}</Text>
        </View>
      </Page>

      {Object.entries(userInformations).map(item => (
        <Page
          size={
            item[1].length > 30
              ? { width: 595.28, height: 28 * item[1].length }
              : 'A4'
          }
          style={styles.section}
          key={item[0]}
        >
          <View style={styles.container}>
            <Text style={styles.name}>
              {item[0]} | {sumHours(item)}
            </Text>
            <View style={styles.table}>
              <View style={styles.header}>
                <Text style={styles.headerItem}>Dia</Text>
                <Text style={styles.headerItem}>Entrada</Text>
                <Text style={styles.headerItem}>Saída</Text>
                <Text style={styles.headerItem}>Total</Text>
              </View>

              {item[1].map(info => (
                <View
                  style={
                    isWeekend(info.date) ? styles.isWeekendBody : styles.body
                  }
                  key={info.id}
                >
                  <Text style={styles.bodyItem}>
                    {convertToDate(info.date)}
                  </Text>
                  <Text style={styles.bodyItem}>
                    {convertHours(info.time_start)}
                  </Text>
                  <Text style={styles.bodyItem}>
                    {convertHours(info.time_finish)}
                  </Text>
                  <Text style={styles.bodyItem}>
                    {getDifferenceHours(info.time_start, info.time_finish)}
                  </Text>
                </View>
              ))}

              <View style={styles.total}>
                <Text style={styles.totalText}>Total: {sumHours(item)}</Text>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

const delay = t => new Promise(resolve => setTimeout(resolve, t));

async function getProps({
  project,
  startDate,
  endDate,
  reportData,
  userInformations,
}) {
  await delay(1000);
  return {
    project,
    startDate,
    endDate,
    reportData,
    userInformations,
  };
}

export const LazyDownloadPDFButton = ({
  project,
  startDate,
  endDate,
  reportData,
}) => {
  const [userInformations, setUserInformations] = useState({} as unknown);

  useEffect(() => {
    handlePDF();
  }, [reportData, project, startDate, endDate]);

  function handlePDF() {
    const usersArray = [];
    const userAppointments = {} as any;
    const sortedAppoint = {} as any;

    reportData.map(item => {
      return usersArray.push(item?.user_id?.username);
    });

    const unique = usersArray.filter((v, i, a) => a.indexOf(v) === i);

    unique.map(item => {
      userAppointments[item] = [];
      sortedAppoint[item] = [];

      return item;
    });

    reportData.map((item: any) => {
      userAppointments[item?.user_id?.username].push(item);
      return item;
    });

    const sortedTimes = Object.entries(userAppointments);

    sortedTimes.map(item => {
      const sortedAppointments = item[1] as any;

      const sortedByDate = sortedAppointments.sort(
        (a, b) =>
          a.date.localeCompare(b.date) ||
          a.time_start
            .split(':')
            .reverse()
            .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0) -
            b.time_start
              .split(':')
              .reverse()
              .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0),
      );

      sortedAppoint[item[0]].push(...sortedByDate);

      return item;
    });

    setUserInformations(sortedAppoint);
  }

  return (
    <button
      type="button"
      onClick={async () => {
        const props = await getProps({
          project,
          startDate,
          endDate,
          reportData,
          userInformations,
        });
        const doc = <DocumentPdf {...props} />;
        const asPdf = pdf([] as any);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, 'Relatorio_Funcionarios.pdf');
      }}
    >
      <FaFilePdf />
    </button>
  );
};
