import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../styles/constants';

import Login from '../../assets/Login.png';

interface InputProps {
  hasErrors: boolean;
}

export const Container = styled.div`
  background: url(${Login}) no-repeat center center;
  background-size: cover;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Forgot = styled.div`
  margin: 4px 0 24px 0;

  display: flex;
  justify-content: flex-end;

  a {
    display: flex;
    align-items: center;
    color: ${COLORS.text};
    margin-left: auto;

    font-size: 13px;

    svg {
      margin-right: 4px;
    }
  }
`;

export const Box = styled(motion.div)`
  background-color: ${COLORS.white};
  width: 100%;
  height: 100%;
  min-height: 550px;
  max-width: 550px;
  border-radius: 10px;
  padding: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    padding: 60px 20px;
  }

  > img {
    width: 180px;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 32px;
    color: ${COLORS.text};

    font-family: 'Circular-Std-Bold', Arial, Helvetica, sans-serif;

    @media (max-width: 500px) {
      font-size: 26px;
      text-align: center;
    }
  }

  p {
    opacity: 0.5;
    color: ${COLORS.text};
    font-size: 12px;
    margin-bottom: 30px;

    font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

    @media (max-width: 500px) {
      font-size: 11px;
      text-align: center;
    }
  }
`;

export const Input = styled.input<InputProps>`
  border: 1px solid;
  border-color: ${props => (props.hasErrors ? 'red' : 'rgba(40, 41, 39, 0.2)')};
  border-radius: 500px;
  font-size: 14px;
  color: ${props => (props.hasErrors ? 'red' : `${COLORS.text}`)};
  width: 100%;
  height: 50px;
  padding: 0 30px;
  margin-bottom: 10px;
  appearance: none;

  transition: all 180ms ease-in-out 0s;

  :focus {
    border-color: ${COLORS.primary};
    color: ${COLORS.text};
  }

  :hover {
    ::placeholder {
      color: ${COLORS.text};
    }
  }

  ::placeholder {
    transition: all 180ms ease-in-out 0s;

    :focus {
      color: ${COLORS.text};
    }
  }

  ::placeholder {
    color: rgba(40, 41, 39, 0.5);
  }
`;

export const Button = styled.button`
  height: 50px;
  font-size: 16px;
  color: ${COLORS.white};
  background-color: ${COLORS.primary};
  width: 100%;
  border: 1px solid ${COLORS.primary};
  border-radius: 500px;
  margin-top: 20px;

  font-family: 'Circular-Std-Medium', Arial, Helvetica, sans-serif;

  transition: all 180ms ease-in-out 0s;

  :hover {
    background-color: transparent;
    color: ${COLORS.primary};
  }
`;
