import React from 'react';

import { VscChromeClose } from 'react-icons/vsc';
import { Container } from './styles';

import { TOOLTIP_ANIMATION } from './animations';

const Tooltip = ({ message, toggleTooltip }) => {
  return (
    <Container
      variants={TOOLTIP_ANIMATION}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      {message}
      <button type="button" onClick={toggleTooltip}>
        <VscChromeClose />
      </button>
    </Container>
  );
};

export default Tooltip;
